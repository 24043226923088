import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Offcanvas, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { useAuth } from '../contexts/authenticationContext';
import Restricted from '../contexts/permissions/Restricted';
import { UserPermissions } from '../contexts/permissions/userPermissions';
import { getApplicationLogoSvg } from '../util/host';

const Sidebar = ({ appName, hasVerificationAlert }) => {
    const { isLoggedIn, logout, user } = useAuth();
    const location = useLocation();
    const [active, setActive] = useState('dashboard');
    const [showSidebar, setShowSidebar] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    
    // For mobile view
    const handleCloseSidebar = () => setShowSidebar(false);
    const handleShowSidebar = () => setShowSidebar(true);
    
    // Function to adjust main content width
    const adjustMainContentWidth = (collapsed) => {
        const mainContent = document.querySelector('main.content-with-sidebar');
        if (mainContent) {
            if (collapsed) {
                mainContent.style.marginLeft = '70px';
            } else {
                mainContent.style.marginLeft = '250px';
            }
        }
    };
    
    // For desktop view - toggle sidebar collapse
    const toggleSidebar = () => {
        const newCollapsedState = !isCollapsed;
        setIsCollapsed(newCollapsedState);
        
        // Directly adjust the main content width
        adjustMainContentWidth(newCollapsedState);
        
        // Store preference in localStorage
        localStorage.setItem('sidebarCollapsed', newCollapsedState);
    };

    useEffect(() => {
        const path = location.pathname.split('/')[1] || 'dashboard';
        setActive(path);
        
        // Load sidebar state from localStorage
        const savedState = localStorage.getItem('sidebarCollapsed');
        if (savedState !== null) {
            const isCollapsedState = savedState === 'true';
            setIsCollapsed(isCollapsedState);
            
            // Set initial main content width
            setTimeout(() => {
                adjustMainContentWidth(isCollapsedState);
            }, 100);
        }
    }, [location]);

    const handleLogout = () => {
        logout();
        handleCloseSidebar();
    };

    // Sidebar content for desktop (collapsible)
    const sidebarContent = (
        <div className={`sidebar d-flex flex-column p-3 bg-light h-100 ${isCollapsed ? 'sidebar-collapsed' : ''}`}>
            <div className="flex-grow-0 d-flex justify-content-between align-items-center position-relative">
                {!isCollapsed && (
                    <Link to="/dashboard" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
                        <img
                            src={getApplicationLogoSvg()}
                            width="auto"
                            height="40"
                            className="me-2"
                            alt={appName}
                        />
                    </Link>
                )}
                {isCollapsed && (
                    <div className="text-center w-100 mb-3">
                        <img
                            src="/favicon.png"
                            width="30"
                            height="30"
                            alt={appName}
                        />
                    </div>
                )}
            </div>
            <hr className="mb-2" />
            
            <div className="flex-grow-1 overflow-auto">
                <Nav className="flex-column" activeKey={active} onSelect={(eventKey) => setActive(eventKey)}>
                    <Nav.Link as={Link} to="/dashboard" eventKey="dashboard" className={active === 'dashboard' ? 'active' : ''} onClick={handleCloseSidebar} title="Dashboard">
                        <i className="bi bi-graph-up me-2"></i>
                        {!isCollapsed && <span>Dashboard</span>}
                    </Nav.Link>
                    <Nav.Link as={Link} to="/registration" eventKey="registration" className={active === 'registration' ? 'active' : ''} onClick={handleCloseSidebar} title="Registration">
                        <i className="bi bi-clipboard-plus me-2"></i>
                        {!isCollapsed && <span>Registration</span>}
                    </Nav.Link>
                    <Restricted to={UserPermissions.CREATE_REPORT}>
                        <Nav.Link as={Link} to="/report" eventKey="report" className={active === 'report' ? 'active' : ''} onClick={handleCloseSidebar} title="Report">
                            <i className="bi bi-file-earmark-text me-2"></i>
                            {!isCollapsed && <span>Report</span>}
                        </Nav.Link>
                    </Restricted>
                    <Nav.Link as={Link} to="/patient" eventKey="patient" className={active === 'patient' ? 'active' : ''} onClick={handleCloseSidebar} title="Patient">
                        <i className="bi bi-person me-2"></i>
                        {!isCollapsed && <span>Patient</span>}
                    </Nav.Link>
                    <Nav.Link as={Link} to="/test-group" eventKey="test-group" className={active === 'test-group' ? 'active' : ''} onClick={handleCloseSidebar} title="Test Group">
                        <i className="bi bi-collection me-2"></i>
                        {!isCollapsed && <span>Test Group</span>}
                    </Nav.Link>
                    <Restricted to={UserPermissions.MANAGE_USER}>
                        <Nav.Link as={Link} to="/user" eventKey="user" className={active === 'user' ? 'active' : ''} onClick={handleCloseSidebar} title="User">
                            <i className="bi bi-people me-2"></i>
                            {!isCollapsed && <span>User</span>}
                        </Nav.Link>
                    </Restricted>
                    <Restricted to={UserPermissions.MANAGE_REFERER}>
                        <Nav.Link as={Link} to="/referer" eventKey="referer" className={active === 'referer' ? 'active' : ''} onClick={handleCloseSidebar} title="Referer">
                            <i className="bi bi-person-badge me-2"></i>
                            {!isCollapsed && <span>Referer</span>}
                        </Nav.Link>
                    </Restricted>
                    <Restricted to={UserPermissions.MANAGE_PAGE_SETUP}>
                        <Nav.Link as={Link} to="/page-setup" eventKey="page-setup" className={active === 'page-setup' ? 'active' : ''} onClick={handleCloseSidebar} title="Page Setup">
                            <i className="bi bi-gear me-2"></i>
                            {!isCollapsed && <span>Page Setup</span>}
                        </Nav.Link>
                    </Restricted>
                </Nav>
            </div>
            
            <div className="mt-auto flex-grow-0">
                <hr className="mt-2" />
                <div className="sidebar-user position-relative">
                    <Dropdown drop="up" align={isCollapsed ? "end" : "start"}>
                        <Dropdown.Toggle variant="light" id="dropdown-user" className="d-flex align-items-center w-100 bg-transparent border-0 p-0" title={user?.name || "User Profile"}>
                            <i className="bi bi-person-circle me-2 fs-4"></i>
                            {!isCollapsed && <span className="me-auto">{user?.name || user?.userType}</span>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={isCollapsed ? "dropdown-menu-end" : ""} popperConfig={{
                            strategy: 'fixed',
                            modifiers: [
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        boundary: document.body,
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: isCollapsed ? [0, 10] : [10, 10],
                                    },
                                },
                            ],
                        }}>
                            <Dropdown.Item as={Link} to="/profile" onClick={handleCloseSidebar}>
                                <i className="bi bi-person me-2"></i>Profile
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"></i>Sign out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                
                {/* Moved toggle button to the bottom of sidebar */}
                <div className="text-center mt-3">
                    <Button 
                        variant="outline-primary" 
                        size="sm" 
                        className="d-none d-md-inline-block sidebar-toggle-btn"
                        onClick={toggleSidebar}
                        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
                    >
                        <i className={`bi ${isCollapsed ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
                        {!isCollapsed && <span className="ms-1">Collapse</span>}
                    </Button>
                </div>
            </div>
        </div>
    );

    // Mobile sidebar content (always expanded)
    const mobileSidebarContent = (
        <div className="sidebar d-flex flex-column p-3 bg-light h-100">
            <div className="flex-grow-0 d-flex justify-content-between align-items-center">
                <Link to="/dashboard" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
                    <img
                        src={getApplicationLogoSvg()}
                        width="auto"
                        height="40"
                        className="me-2"
                        alt={appName}
                    />
                </Link>
            </div>
            <hr className="mb-2" />
            
            <div className="flex-grow-1 overflow-auto">
                <Nav className="flex-column" activeKey={active} onSelect={(eventKey) => setActive(eventKey)}>
                    <Nav.Link as={Link} to="/dashboard" eventKey="dashboard" className={active === 'dashboard' ? 'active' : ''} onClick={handleCloseSidebar}>
                        <i className="bi bi-graph-up me-2"></i>
                        <span>Dashboard</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/registration" eventKey="registration" className={active === 'registration' ? 'active' : ''} onClick={handleCloseSidebar}>
                        <i className="bi bi-clipboard-plus me-2"></i>
                        <span>Registration</span>
                    </Nav.Link>
                    <Restricted to={UserPermissions.CREATE_REPORT}>
                        <Nav.Link as={Link} to="/report" eventKey="report" className={active === 'report' ? 'active' : ''} onClick={handleCloseSidebar}>
                            <i className="bi bi-file-earmark-text me-2"></i>
                            <span>Report</span>
                        </Nav.Link>
                    </Restricted>
                    <Nav.Link as={Link} to="/patient" eventKey="patient" className={active === 'patient' ? 'active' : ''} onClick={handleCloseSidebar}>
                        <i className="bi bi-person me-2"></i>
                        <span>Patient</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/test-group" eventKey="test-group" className={active === 'test-group' ? 'active' : ''} onClick={handleCloseSidebar}>
                        <i className="bi bi-collection me-2"></i>
                        <span>Test Group</span>
                    </Nav.Link>
                    <Restricted to={UserPermissions.MANAGE_USER}>
                        <Nav.Link as={Link} to="/user" eventKey="user" className={active === 'user' ? 'active' : ''} onClick={handleCloseSidebar}>
                            <i className="bi bi-people me-2"></i>
                            <span>User</span>
                        </Nav.Link>
                    </Restricted>
                    <Restricted to={UserPermissions.MANAGE_REFERER}>
                        <Nav.Link as={Link} to="/referer" eventKey="referer" className={active === 'referer' ? 'active' : ''} onClick={handleCloseSidebar}>
                            <i className="bi bi-person-badge me-2"></i>
                            <span>Referer</span>
                        </Nav.Link>
                    </Restricted>
                    <Restricted to={UserPermissions.MANAGE_PAGE_SETUP}>
                        <Nav.Link as={Link} to="/page-setup" eventKey="page-setup" className={active === 'page-setup' ? 'active' : ''} onClick={handleCloseSidebar}>
                            <i className="bi bi-gear me-2"></i>
                            <span>Page Setup</span>
                        </Nav.Link>
                    </Restricted>
                </Nav>
            </div>
            
            <div className="mt-auto flex-grow-0">
                <hr className="mt-2" />
                <div className="sidebar-user position-relative">
                    <Dropdown drop="up" align="start">
                        <Dropdown.Toggle variant="light" id="dropdown-user" className="d-flex align-items-center w-100 bg-transparent border-0 p-0">
                            <i className="bi bi-person-circle me-2 fs-4"></i>
                            <span className="me-auto">{user?.name || user?.userType}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu popperConfig={{
                            strategy: 'fixed',
                            modifiers: [
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        boundary: document.body,
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [10, 10],
                                    },
                                },
                            ],
                        }}>
                            <Dropdown.Item as={Link} to="/profile" onClick={handleCloseSidebar}>
                                <i className="bi bi-person me-2"></i>Profile
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"></i>Sign out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {isLoggedIn && (
                <>
                    {/* Mobile Toggle Button - Updated positioning */}
                    <div className="d-md-none sidebar-toggle" style={{ 
                        position: 'fixed',
                        top: hasVerificationAlert ? '60px' : '10px',
                        left: '10px',
                        zIndex: 1200  // Increased z-index to be above the alert
                    }}>
                        <Button variant="primary" onClick={handleShowSidebar}>
                            <i className="bi bi-list"></i>
                        </Button>
                    </div>

                    {/* Mobile Sidebar */}
                    <Offcanvas show={showSidebar} onHide={handleCloseSidebar} className="d-md-none">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{appName}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                            {mobileSidebarContent}
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/* Desktop Sidebar */}
                    <div className={`d-none d-md-block position-fixed sidebar-container ${isCollapsed ? 'sidebar-container-collapsed' : ''}`}
                        style={{ 
                            width: isCollapsed ? '70px' : '250px', 
                            zIndex: 1000,
                            top: hasVerificationAlert ? '50px' : '0',
                            bottom: 0,
                            overflow: 'hidden',
                            transition: 'width 0.3s ease'
                        }}>
                        {sidebarContent}
                    </div>
                </>
            )}
        </>
    );
};

export default Sidebar; 