import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Spinner, Badge, Button, Modal, Form, FloatingLabel } from "react-bootstrap";
import { getUserProfile, changePassword } from "../service/Reportify";
import { toast } from "../util/toast";
import { useFormik } from "formik";
import PageTitle from './compo/PageTitle';

// Change Password Modal Component
function ChangePasswordModal({ show, handleClose }) {
    const changePasswordHandel = async (values, { resetForm }) => {
        if (values.newPassword && values.confirmPassword && values.newPassword !== values.confirmPassword) {
            toast.warning('Passwords do not match');
            return;
        }
        
        if (values.currentPassword && values.newPassword) {
            changePassword(values)
                .then(value => {
                    resetForm();
                    toast.success('Password changed successfully');
                    handleClose();
                })
                .catch(reason => {
                    toast.error(reason.response?.data?.message || 'Failed to change password');
                });
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        onSubmit: changePasswordHandel,
    });

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="bg-primary text-white">
                <Modal.Title>
                    <i className="bi bi-key me-2"></i>
                    Change Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <FloatingLabel
                        controlId="currentPassword"
                        label="Current Password"
                        className="mb-3">
                        <Form.Control
                            type="password"
                            name="currentPassword"
                            placeholder="Current Password"
                            onChange={formik.handleChange}
                            value={formik.values.currentPassword}
                            required
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="newPassword"
                        label="New Password"
                        className="mb-3">
                        <Form.Control
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            onChange={formik.handleChange}
                            value={formik.values.newPassword}
                            required
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="confirmPassword"
                        label="Confirm Password"
                        className="mb-3">
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            required
                        />
                        {formik.values.newPassword && formik.values.confirmPassword && 
                         formik.values.newPassword !== formik.values.confirmPassword && (
                            <Form.Text className="text-danger">
                                Passwords do not match
                            </Form.Text>
                        )}
                    </FloatingLabel>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="submit">
                            Change Password
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

function Profile() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const handleClosePasswordModal = () => setShowPasswordModal(false);
    const handleShowPasswordModal = () => setShowPasswordModal(true);

    useEffect(() => {
        setLoading(true);
        getUserProfile()
            .then(value => {
                setUser(value.data);
            })
            .catch(error => {
                console.error("Error fetching user profile:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // Helper function to safely get user initials
    const getUserInitials = (name) => {
        if (!name) return "U";
        return name.split(' ')
            .map(n => n[0])
            .join('')
            .toUpperCase();
    };

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    // If user data is not available, show an error message
    if (!user) {
        return (
            <Container className="py-4">
                <PageTitle iconName="bi bi-person-circle" title="User Profile" />
                <div className="alert alert-danger mt-4">
                    <i className="bi bi-exclamation-triangle-fill me-2"></i>
                    Failed to load user profile. Please try again later.
                </div>
            </Container>
        );
    }

    return (
        <Container className="py-4">
            <div className="d-flex justify-content-between align-items-center">
                <PageTitle iconName="bi bi-person-circle" title="User Profile" />
                <Button 
                    variant="outline-primary" 
                    onClick={handleShowPasswordModal}
                    className="d-flex align-items-center"
                >
                    <i className="bi bi-key me-2"></i>
                    Change Password
                </Button>
            </div>
            
            <Row className="mt-4">
                {/* User Information Card */}
                <Col lg={6} className="mb-4">
                    <Card className="shadow-sm h-100">
                        <Card.Header className="bg-primary text-white d-flex align-items-center">
                            <i className="bi bi-person-fill me-2 fs-5"></i>
                            <h5 className="mb-0">User Information</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center mb-4">
                                <div className="avatar-circle mx-auto mb-3">
                                    <span className="avatar-initials">
                                        {getUserInitials(user.name)}
                                    </span>
                                </div>
                                <h4>{user.name || 'User'}</h4>
                                <p className="text-muted mb-2">{user.userType || 'User'}</p>
                                <div>
                                    {user.isEmailVerified ? (
                                        <Badge bg="success" className="me-2">
                                            <i className="bi bi-check-circle me-1"></i> Email Verified
                                        </Badge>
                                    ) : (
                                        <Badge bg="warning" className="me-2">
                                            <i className="bi bi-exclamation-circle me-1"></i> Email Not Verified
                                        </Badge>
                                    )}
                                    {user.userCode && (
                                        <Badge bg="info">
                                            <i className="bi bi-code me-1"></i> {user.userCode}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                            
                            <div className="profile-info">
                                <div className="profile-info-item">
                                    <div className="profile-info-label">
                                        <i className="bi bi-envelope me-2"></i>
                                        Email
                                    </div>
                                    <div className="profile-info-value">{user.email || 'Not available'}</div>
                                </div>
                                
                                {user.userType && (
                                    <div className="profile-info-item">
                                        <div className="profile-info-label">
                                            <i className="bi bi-person-badge me-2"></i>
                                            User Type
                                        </div>
                                        <div className="profile-info-value">{user.userType}</div>
                                    </div>
                                )}
                                
                                {user.userCode && (
                                    <div className="profile-info-item">
                                        <div className="profile-info-label">
                                            <i className="bi bi-key me-2"></i>
                                            User Code
                                        </div>
                                        <div className="profile-info-value">{user.userCode}</div>
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                
                {/* Business Information Card */}
                <Col lg={6} className="mb-4">
                    <Card className="shadow-sm h-100">
                        <Card.Header className="bg-success text-white d-flex align-items-center">
                            <i className="bi bi-building me-2 fs-5"></i>
                            <h5 className="mb-0">Business Information</h5>
                        </Card.Header>
                        <Card.Body>
                            {user.tenant ? (
                                <>
                                    <div className="text-center mb-4">
                                        <div className="business-logo mx-auto mb-3">
                                            <i className="bi bi-building fs-1"></i>
                                        </div>
                                        <h4>{user.tenant.name || 'Business'}</h4>
                                        <p className="text-muted mb-3">{user.tenant.email || 'No email available'}</p>
                                        {user.tenant.subscriptionPlan && (
                                            <Badge 
                                                bg={user.tenant.subscriptionPlan === 'FREE' ? 'secondary' : 'primary'} 
                                                className="px-3 py-2"
                                            >
                                                <i className="bi bi-star me-1"></i>
                                                {user.tenant.subscriptionPlan} Plan
                                            </Badge>
                                        )}
                                    </div>
                                    
                                    <div className="profile-info">
                                        <div className="profile-info-item">
                                            <div className="profile-info-label">
                                                <i className="bi bi-building me-2"></i>
                                                Business Name
                                            </div>
                                            <div className="profile-info-value">{user.tenant.name || 'Not available'}</div>
                                        </div>
                                        
                                        <div className="profile-info-item">
                                            <div className="profile-info-label">
                                                <i className="bi bi-envelope me-2"></i>
                                                Business Email
                                            </div>
                                            <div className="profile-info-value">{user.tenant.email || 'Not available'}</div>
                                        </div>
                                        
                                        <div className="profile-info-item">
                                            <div className="profile-info-label">
                                                <i className="bi bi-calendar-check me-2"></i>
                                                Subscription Expires
                                            </div>
                                            <div className="profile-info-value">
                                                {user.tenant?.subscriptionExpireAt || 'Not Available'}
                                                {user.tenant?.subscriptionExpireAt && (
                                                    <Badge 
                                                        bg="warning" 
                                                        className="ms-2"
                                                        title="Days remaining"
                                                    >
                                                        {getDaysRemaining(user.tenant.subscriptionExpireAt)}
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="text-center py-5">
                                    <i className="bi bi-building fs-1 text-muted mb-3 d-block"></i>
                                    <h5 className="text-muted">No business information available</h5>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            {/* Change Password Modal */}
            <ChangePasswordModal 
                show={showPasswordModal} 
                handleClose={handleClosePasswordModal} 
            />
        </Container>
    );
}

// Helper function to calculate days remaining until subscription expiry
function getDaysRemaining(expiryDateStr) {
    if (!expiryDateStr) return '';
    
    try {
        const expiryDate = new Date(expiryDateStr);
        const today = new Date();
        const diffTime = expiryDate - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        return diffDays > 0 ? `${diffDays} days` : 'Expired';
    } catch (e) {
        return '';
    }
}

export default Profile;
