import React from "react";
import {Col, Row, Stack} from "react-bootstrap";
import {displayDate} from "../util/date";
import {
    buildReportHeader,
    capitalizeFirstLetter,
    generateBarcodeUrl,
} from "../util/utility";

export const getPatientPrintComponent = (patient, reportingTime) => {
    return <div style={{ border: '1.5px solid #000000', padding: '8px', fontSize: '13px' }} className={'my-1'}>
        <Row>
            <Col sm={11}>
                <Row>
                    <Col style={{ fontWeight: "bold" }} sm={2}>Name</Col>
                    <Col style={{ fontWeight: 'bold' }} sm={5}>: {patient.title} {patient.name}</Col>
                    <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Patient UID</Col>
                    <Col style={{ fontWeight: "bold" }} sm={3}>: {patient?.patientCode || ''}</Col>
                </Row>
                <Row>
                    <Col style={{ fontWeight: "bold" }} sm={2}>Age/Gender</Col>
                    <Col sm={5}>: {patient.age} {capitalizeFirstLetter(patient?.ageIn)} / {capitalizeFirstLetter(patient.gender)}</Col>
                    <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Booked on</Col>
                    <Col sm={3}>: {displayDate(patient.registrationDate)}</Col>
                </Row>
                <Row>
                    <Col style={{ fontWeight: "bold" }} sm={2}>Refer By</Col>
                    <Col sm={5}>: {patient.referBy}</Col>
                    <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Collected on</Col>
                    <Col sm={3}>: {displayDate(patient.updatedAt)}</Col>
                </Row>
                <Row>
                    <Col style={{ fontWeight: "bold" }} sm={2}>Collected By</Col>
                    <Col sm={5}>: Main Center</Col>
                    <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Reported on</Col>
                    <Col sm={3}>: {displayDate(reportingTime)}</Col>
                </Row>
                <Row>
                    <Col style={{ fontWeight: "bold" }} sm={2}>Doctor Name</Col>
                    <Col sm={5}>: {patient?.doctorName}</Col>
                    <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}/>
                    <Col sm={3}/>
                </Row>
            </Col>
            <Col sm={1} className="d-flex align-items-center justify-content-center">
                {patient.patientCode && <img style={{transform: 'rotate(90deg)', marginRight: '10px'}}
                      src={generateBarcodeUrl(patient.patientCode)} alt={`Barcode for ${patient.patientCode}`}/>}
            </Col>
        </Row>
    </div>
}

export const getReportForPrint = (patientTestGroup, isLastPage, qrCodeUrl) => {
    return <>
        {
            patientTestGroup.testGroup.reportType === "PARAMETER" ?
            getReportParameterTable(patientTestGroup) :
            getPlainTextReport(patientTestGroup)
        }
        <div className={'my-3'}
             style={{fontSize: '12px'}}
             dangerouslySetInnerHTML={{__html: patientTestGroup.report.endReportSummery}}/>
        <div style={{ borderTop: '1.5px solid black', width: '100%' }}/>
        {
            isLastPage &&
            <>
                <p style={{textAlign: "center", margin: 0}}>*** End Of Report ***</p>
                {/* <img src={qrCodeUrl} alt={'qr'} style={{width: 64}}/> */}
            </>
        }
    </>
}

const getPlainTextReport = (patientTestGroup) => {
    return <div style={{overflowX: 'auto', fontSize: '13px'}} className={'my-1'}>
        <div dangerouslySetInnerHTML={{__html: patientTestGroup.report.plainText}}/>
    </div>
}

const getReportParameterTable = (patientTestGroup) => {
    return <div style={{overflowX: 'auto', fontSize: '13px'}} className={'my-1'}>
        <table style={{width: '100%'}}>
            <thead className={'bg-secondary'}>
            <tr className={'p-2'}>
                <th style={{width: "40%"}}>Test Name</th>
                <th style={{width: "20%"}}>Observed Value</th>
                <th style={{width: "15%"}}>Units</th>
                <th style={{width: "25%"}}>Reference Range</th>
            </tr>
            </thead>
            <tbody>
            <tr className={'text-center my-5'}>
                <td colSpan={4} className={'text-center'}
                    style={{fontWeight: 'bold', textDecoration: 'underline', fontSize: '16px'}}>
                    {patientTestGroup.testGroup.nameOnReport || patientTestGroup.name}
                </td>
            </tr>
            {
                patientTestGroup.report.parametersValues.map((pv) => (
                    <tr key={pv.name}>
                        {pv.isLabel ?
                            <td colSpan={5} style={{verticalAlign: 'top', fontWeight: 'bold', textDecoration: 'underline'}}>
                                {pv.name}
                            </td> :
                            <td style={{verticalAlign: 'top'}}>{pv.remark && pv.remark.length > 0 ? (
                                <>
                                    {pv.name}<br/>
                                    <span style={{fontStyle: 'italic', fontSize:'10px'}}>{pv.remark}</span>
                                </>
                            ) : pv.name}</td>
                        }
                        {
                            !pv.isLabel && <>
                                <td style={{fontWeight: pv.isAbNormal ? 'bold' : 'normal', verticalAlign: 'top'}}>{pv.value}</td>
                                <td style={{verticalAlign: 'top'}}>{pv.unit}</td>
                                <td style={{verticalAlign: 'top', whiteSpace: 'pre-wrap'}}>{pv.reference}</td>
                            </>
                        }
                    </tr>
                ))
            }
            </tbody>
        </table>
    </div>
}


export const getSignature = (page, qrCodeUrl, isLast ) => {
    if (!page || !page?.signatures) return;
    return <Row>
            <Stack direction="horizontal" className="align-items-end">
            {isLast && <img src={qrCodeUrl} alt={'qr'} style={{width: 64, alignSelf: 'center'}}/> }
                    {page.signatures.map((signature, index) => {
                        return <div className="ms-auto" key={index}>
                            {
                                page.fullPage.signatureOnPrint && 
                                <img src={signature.signatureImageKey} style={{height: '1cm', width: '3cm'}} alt={'signature'}/>
                            }
                            {
                                page.fullPage.doctorNameOnPrint && <>
                                    <h6 style={{fontSize: '14px'}}>{signature.title}</h6>
                                    <p style={{whiteSpace: 'pre-wrap', fontSize: '12px'}}>{signature.subTitle}</p>
                                </>
                            }
                        </div>
                    })}
            </Stack>
    </Row>
}

export const getHeader = (page, latterHeadPrint) => {
    return <div>
        {latterHeadPrint ?
            <div style={{height: page.header.headerHeight, padding: page.header.headerPadding}}/>
            : page && buildReportHeader(page.header)
        }
    </div>
}
