import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Container, Stack, Table} from "react-bootstrap";
import {displayDate} from "../util/date";
import {useReactToPrint} from "react-to-print";
import {getPage, getPatient} from "../service/Reportify";
import {
    capitalizeFirstLetter,
    generateQRCodeDataUrl,
    getEmailMessage, getPublicViewReportUrl,
    getWhatsAppMessage,
    resolveDownload
} from "../util/utility";
import {getPatientPrintComponent, getReportForPrint, getSignature} from "../print/PrintHealper";
import {PDFDownloadLink} from '@react-pdf/renderer';
import TestReportPdf from "../pdf/TestReportPdf";


const labelStyleLeft = {
    width: 'auto',
    textAlign: 'left',
    fontWeight: 'bold',
};

const labelStyleRight = {
    width: 'auto',
    textAlign: 'right',
    fontWeight: 'bold',
};

function PatientDetail(props) {
    const navigator = useNavigate();
    const {patientId} = useParams();
    const [page, setPage] = useState(null);
    const [patient, setPatient] = useState();
    const [printClicked, setPrintClicked] = useState('none');
    const [printPatientTestGroups, setPrintPatientTestGroups] = useState([]);
    const [checkboxes, setCheckboxes] = useState({}); // Initialize as an object
    const componentRef = useRef();
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');


    useEffect(() => {
        getPatient(patientId)
            .then(value => setPatient(value.data))
            .catch(reason => {
                console.log('error', reason)
            })
        getPage().then(async value => {
            const body = value.data.length > 0 ? value.data[0] : null;
            if (body) {
                if (body?.signatures)
                    body.signatures = await Promise.all(body.signatures.map(async (sig) => {
                        sig.signatureImageKey = await resolveDownload(sig.signatureImageKey);
                        return sig;
                    }));
            }
            setPage(body)
        })
    }, [])

    useEffect(()=>{
        if (patient) {
            generateQRCodeDataUrl(getPublicViewReportUrl(patient))
                .then(value => {
                    setQrCodeDataUrl(value)
                })
        }
    },[patient])

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setCheckboxes({...checkboxes, [name]: checked});
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => (setPrintClicked('none')),
        copyStyles: true,
        pageStyle: `
          @media print {
            body {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
            color-adjust: exact !important;                 /* Firefox 48 – 96 */
            print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
            }
    
            @page {
              size: A4;
              margin-top: ${page?.fullPage?.headerHeight || '4.5cm'} !important;
              margin-bottom: ${page?.fullPage?.footerHeight || '2.5cm'} !important;
              margin-left: ${page?.fullPage?.padding || '5mm'} !important;
              margin-right: ${page?.fullPage?.padding || '5mm'} !important;
            }
            
            #app-name,
            #date-time {
              display: none;
            }
    
            .print-page {
              position: relative;
              page-break-before: always; /* Ensure a new page for each "print-page" div */
            }
        `,
    });

    const handelReportPrint = (patientTestGroup, index) => {
        setPrintPatientTestGroups([patientTestGroup])
        setPrintClicked('')
        setTimeout(() => {
            handlePrint();
        }, 100)
    }

    const handlePrintAllReport = () => {
        setPrintPatientTestGroups(patient?.patientTestGroups?.filter((ptg) => ptg.isReportCompleted === true))
        setPrintClicked('')
        setTimeout(() => {
            handlePrint();
        }, 100)
    }

    const generateAndDownloadPDF = () => {
        console.log("Generate PDF")
        // navigator(`/view-pdf/${patientId}`)

    }

    const handleReportDownloadClick = async () => {
        const pdfUrl = patient.reportUrl; // Replace with the actual PDF URL
        if (!pdfUrl) return

        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();

            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${patient.name}_${displayDate(Date.now())}_report.pdf`; // You can set the desired filename here
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handelViewPdf = () => {
        window.open(`${window.location}/view-report`)
    }

    return (
        patient && <>
            <Container>
                <Table className={'my-3'}>
                    <tbody>
                    <tr>
                        <th style={labelStyleLeft}>Patient Id:</th>
                        <td>{patient.patientCode}</td>
                        <th style={labelStyleRight}>Name:</th>
                        <td>{patient.title} {patient.name}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Age:</th>
                        <td>{patient.age} {capitalizeFirstLetter(patient?.ageIn)}</td>
                        <th style={labelStyleRight}>Gender:</th>
                        <td>{patient.gender}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Mobile:</th>
                        <td>{patient.mobile}</td>
                        <th style={labelStyleRight}>Registration Time:</th>
                        <td>{displayDate(patient.registrationDate)}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Email:</th>
                        <td>{patient.email}</td>
                        <th style={labelStyleRight}>Sampling Time:</th>
                        <td>{displayDate(patient.registrationDate)}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Care Of:</th>
                        <td>{patient.careOf}</td>
                        <th style={labelStyleRight}>Reporting Time:</th>
                        <td>{displayDate(patient?.patientTestGroups[0]?.reportingTime || patient.registrationDate)}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Address:</th>
                        <td>{patient.address}</td>
                        <th style={labelStyleRight}>Referred By:</th>
                        <td>{patient.referBy}</td>
                    </tr>
                    <tr>
                        <th style={labelStyleLeft}>Doctor Name:</th>
                        <td>{patient?.doctorName}</td>
                        <th style={labelStyleRight}/>
                        <td/>
                    </tr>
                    </tbody>
                </Table>
                <Stack direction="horizontal" className={'my-2'} style={{justifyContent: 'flex-end'}}>
                    {
                        patient && <>
                            {/*<button type="button" className="btn btn-lg" onClick={handelViewPdf}>*/}
                            {/*    <i className="bi-file-pdf"/>*/}
                            {/*</button>*/}
                            {/*<button type="button" className="btn btn-lg" onClick={handleReportDownloadClick}>*/}
                            {/*    <i className="bi-download"/>*/}
                            {/*</button>*/}
                            <ButtonMailto
                                email={patient.email || ''}
                                subject={`Your Diagnostic Report is Ready for Download`}
                                body={getEmailMessage(patient.name, getPublicViewReportUrl(patient))}
                            />
                            <WhatsAppButton
                                text={getWhatsAppMessage(patient.name, getPublicViewReportUrl(patient))}
                                phone={patient.mobile}
                            />
                        </>
                    }
                    <button type="button"
                            className="btn btn-lg"
                            onClick={() => {
                                window.open(`${window.location}/view-report?isLetterhead=true`,'_blank');
                            }}>
                        <i className="bi-printer"/> With Letterhead
                    </button>
                    <button type="button"
                            className="btn btn-lg"
                            onClick={() => {
                                window.open(`${window.location}/view-report?isLetterhead=false`,'_blank');
                            }}>
                        <i className="bi-printer"/> Without Letterhead
                    </button>
                    <button type="button"
                            className="btn btn-lg"
                            onClick={() => {
                                handlePrintAllReport();
                            }}>
                        <i className="bi-printer"/>
                    </button>
                </Stack>
                {
                    patient.patientTestGroups.map((ptg, ptgIndex) => {
                        return <div key={ptgIndex} className={'my-3'}>
                            <Stack direction={"horizontal"} className={'bg-dark-subtle'} gap={3}>
                                <div className={'p-2'}><h5>{ptg.testGroupCode} - {ptg.name}</h5></div>
                                <div className="p-2 ms-auto"/>

                                {ptg.isReportCompleted ? <>
                                    <div className={'p-2'}>
                                        <button type="button"
                                                className="btn btn-lg"
                                                onClick={() => {
                                                    handelReportPrint(ptg, ptgIndex)
                                                }}>
                                            <i className="bi-printer"/>
                                        </button>
                                    </div>
                                </> : <span className={'mx-3'}>Not Completed</span>}
                            </Stack>
                            {ptg?.testGroup?.reportType === "PARAMETER" && ptg.report &&
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>Test Name</th>
                                        <th>Observed Value</th>
                                        <th>Unit</th>
                                        <th>Reference Range</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        ptg.report.parametersValues.map((pv, pvIndex) => {
                                            return <tr id={pvIndex} key={pvIndex}>
                                                {pv.isLabel ?
                                                    <td style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                                                        {pv.name}
                                                    </td> :
                                                    <td>{pv.remark && pv.remark.length > 0 ? (
                                                        <>
                                                            {pv.name}
                                                            <br/>
                                                            <span className={'small'}>{pv.remark}</span>
                                                        </>) : pv.name}</td>
                                                }
                                                <td>{pv.value}</td>
                                                <td>{pv.unit}</td>
                                                <td style={{whiteSpace: 'pre-wrap'}}>{pv.reference}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </Table>
                            }
                            {ptg?.testGroup?.reportType === "PLAIN_TEXT" && ptg.report &&
                                <div>
                                    <div dangerouslySetInnerHTML={{__html: ptg.report.plainText}}/>
                                </div>
                            }
                            <div dangerouslySetInnerHTML={{__html: ptg?.report?.endReportSummery}}/>
                        </div>
                    })
                }
            </Container>

            {printClicked !== 'none' && <div style={{display: printClicked}} ref={componentRef}>
                {
                    printPatientTestGroups?.map((ptg, index) => {
                        const isLast = printPatientTestGroups.length === index+1;
                        if (ptg.isReportCompleted) {
                            return <table style={{width: '100%', fontFamily: 'Arial, sans-serif'}} key={index}>
                                <thead>
                                    {getPatientPrintComponent(patient, ptg?.reportingTime || ptg?.report?.updatedAt)}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {getReportForPrint(ptg, isLast, qrCodeDataUrl)}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot style={{pageBreakAfter: 'always'}}>
                                    {getSignature(page, qrCodeDataUrl, isLast)}
                                </tfoot>
                            </table>
                        }
                    })
                }
            </div>}
        </>
    );
}



const ButtonMailto = ({ email, subject, body, label }) => {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return (
        <button
            type="button"
            className="btn btn-lg"
            onClick={(e) => {
                e.preventDefault();
                window.location.href=mailtoLink;
            }}
        ><i className={'bi-envelope'}/></button>
    );
};

const WhatsAppButton = ({ text, phone }) => {
    if (phone && !phone.includes('+')){
        phone = `+91${phone}`
    }
    const handleWhatsAppShare = async () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}&phone=${phone}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <button
            type="button"
            className="btn btn-lg"
            onClick={(e)=>{
                e.preventDefault();
                handleWhatsAppShare();
            }}
        >
            <i className="bi-whatsapp" />
        </button>
    );
};
export default PatientDetail;
