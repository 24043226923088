import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Container, Dropdown, Form, Row, Table} from "react-bootstrap";
import {useFormik, Field} from "formik";
import {useNavigate} from "react-router-dom";
import {getAllInvoice, getPage} from "../../service/Reportify";
import {getQueryString, resolveDownload, tableHeaderColor} from "../../util/utility";
import MyPagination from "../MyPagination";
import {displayDate} from "../../util/date";
import PrintInvoiceModel from "../models/PrintInvoiceModel";
import InvoiceAdjustBalanceModel from "../models/InvoiceAdjustBalanceModel";
import Restricted from "../../contexts/permissions/Restricted";
import {UserPermissions} from "../../contexts/permissions/userPermissions";
import PageTitle from "../compo/PageTitle";

function RegistrationList(props) {
    const [invoices, setInvoices] = useState(null)
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const navigate = useNavigate();
    // const [currentPage, setCurrentPage] = useState(1);
    const [printInvoiceModalShow, setPrintInvoiceModalShow] = useState(false);
    const [pageSetup , setPageSetup] = useState(null);
    const [showAdjustPriceModel, setShowAdjustPriceModel] = useState(false);


    const fetchInitialData = async () => {
        const response = await getPage();
        const data = response?.data[0];
        const logoImageUrl = await resolveDownload(data?.invoice?.header?.logoImageKey)
        if (logoImageUrl){
            data.invoice.header.logoImageKey = logoImageUrl;
        }
        setPageSetup(data)
    }

    useEffect(()=>{
        fetchInitialData();
        fetchData();
    },[])

    const formik = useFormik({
        initialValues: {search: '', searchBy: ''},
        onSubmit: (values) => {
            searchInvoice(values)
        }
    })

    const searchInvoice = async (values) => {
        const query = getQueryString({search: values.search, searchBy: values.searchBy, page: 1})
        fetchData(query)
    }

    const fetchData = async (initialQuery) => {
        const query = initialQuery ? initialQuery : getQueryString({page: 1})
        getAllInvoice(query).then(value => {
            setInvoices(value.data)
        }).catch(reason => {
            console.log(reason)
        })
    }

    const handelPageChange = (pageNumber) => {
        const values = formik.values;
        const query = getQueryString({search: values.search, searchBy: values.searchBy, page: pageNumber})
        fetchData(query)
    }

    const handlePrintInvoice = (invoice) => {
        setSelectedInvoice(invoice)
        setPrintInvoiceModalShow(true)
    }

    const handlePatientDetail = (invoice) => {
        setSelectedInvoice(invoice)
        navigate(`/patient/${invoice.patient}`)
    }
    const handleEditPatient = (invoice) => {
        setSelectedInvoice(invoice)
        navigate(`/update-patient/${invoice.patient}`)
    }
    const handleAdjustBalance = (invoice) => {
        setSelectedInvoice(invoice)
        setShowAdjustPriceModel(true)
    }

    const handelAdjustBalanceInvoice = (updatedInvoice) => {
        const updatedInvoiceList = invoices.data.map((invoice) =>
            invoice._id === updatedInvoice._id ? updatedInvoice : invoice
        )
        setInvoices({...invoices, data: updatedInvoiceList})
    }

    const handelNewRegistrationClick = () => {
      navigate('/registration/create');
    }

    return (
        <Container className={'my-3'}>
            <PageTitle title={'Registration List'} iconName={'bi-receipt'}>
                <Restricted to={UserPermissions.CREATE_REGISTRATION}>
                    <Button variant={"primary"} onClick={handelNewRegistrationClick}>
                        <i className={'bi bi-plus'}/>New Registration
                    </Button>
                </Restricted>
            </PageTitle>

            <Form className={'mt-3'} 
            onSubmit={formik.handleSubmit} 
            onChange={(e) => {
                if (e.target.value === '') {
                    fetchData('', 1)
                }
                }}>
                <Row className={'mb-3'}>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Invoice No / Patient Name / Mobile No / Email / Refer By / Doctor Name"
                            name="search"
                            id={'search'}
                            onChange={formik.handleChange}
                        />
                    </Col>
                    <Col xs="auto">
                        <Form.Control as="select" 
                        id="searchBy" 
                        name="searchBy" 
                        className="form-select"
                        onChange={formik.handleChange}>
                            <option value="">All</option>
                            <option value="invoiceNumber">Invoice No</option>
                            <option value="patientName">Patient Name</option>
                            <option value="mobile">Mobile No</option>
                            <option value="email">Email</option>
                            <option value="doctorName">Doctor Name</option>
                            <option value="referBy">Refer By</option>
                        </Form.Control>
                    </Col>
                    <Col xs="auto">
                        <Button variant={"outline-success"} type={'submit'}>Search</Button>
                    </Col>
                </Row>
            </Form>
            <InvoiceAdjustBalanceModel
                show={showAdjustPriceModel}
                invoice={selectedInvoice}
                onDecline={()=>{setShowAdjustPriceModel(false); setSelectedInvoice(null)}}
                onSave={handelAdjustBalanceInvoice}
                handleClose={()=>{setShowAdjustPriceModel(false)}}
            />

            <Table striped hover className={'mt-3'}>
                <thead>
                <tr>
                    <td style={tableHeaderColor}>#</td>
                    <th style={tableHeaderColor}>Invoice No</th>
                    <th style={tableHeaderColor}>Patient Name</th>
                    <th style={tableHeaderColor}>Total Amount</th>
                    <th style={tableHeaderColor}>Balance</th>
                    <th style={tableHeaderColor}>Test List</th>
                    <th style={tableHeaderColor}>Refer By</th>
                    <th style={tableHeaderColor}>Date And Time</th>
                    <th style={tableHeaderColor}/>

                </tr>
                </thead>
                <tbody>
                {invoices && invoices.data.map((invoice, index) => (
                    <tr key={invoice._id}>
                        <td>{index + 1}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{invoice.patientInfo?.name}</td>
                        <td>{invoice.total}</td>
                        <td>{invoice.balance}</td>
                        <td>{renderBadges(invoice.items)}</td>
                        <td>{invoice.patientInfo?.referBy}</td>
                        <td>{displayDate(invoice.invoiceDate)}</td>
                        <td><CustomDropdownButton
                            onPrintClick={()=>{handlePrintInvoice(invoice)}}
                            onViewPatientClick={()=>{handlePatientDetail(invoice)}}
                            onEditPatientClick={()=>{handleEditPatient(invoice)}}
                            onAdjustBalance={()=>{handleAdjustBalance(invoice)}}
                        /></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {
                invoices && <MyPagination currentPage={invoices.pageInfo.currentPage} pageLimit={invoices.pageInfo.limit} totalItem={invoices.pageInfo.totalItem} onPageClick={handelPageChange}/>
            }
            {
                pageSetup && <PrintInvoiceModel
                    show={printInvoiceModalShow}
                    onHide={() => {
                        setPrintInvoiceModalShow(false);
                    }}
                    invoice={selectedInvoice}
                    pageSetup={pageSetup}
                />
            }
        </Container>
    );
}

const renderBadges = (patientTestGroups) => {
    const noOfItem = 2;
    const numRows = Math.ceil(patientTestGroups.length / noOfItem);

    const badgeRows = [];
    let startIndex = 0;

    for (let i = 0; i < numRows; i++) {
        const endIndex = startIndex + noOfItem;
        const rowBadges = patientTestGroups.slice(startIndex, endIndex);

        const row = (
            <Row key={i}>
                {rowBadges.map((item, index) => (
                    <Col key={index} style={{maxWidth: "fit-content"}}>
                        <Badge bg={'info'}>{item.code} - {item.name}</Badge>
                    </Col>
                ))}
            </Row>
        );

        badgeRows.push(row);
        startIndex = endIndex;
    }

    return badgeRows;
};

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <button
        className={'btn m-0 p-0 pe-1 ps-1'}
        onClick={(e) => {
            e.stopPropagation();
            onClick(e);
        }}
        ref={ref}>
        {children}
    </button>
));

const CustomDropdownButton = ({ onEditPatientClick, onPrintClick, onViewPatientClick, onAdjustBalance }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>
                <i className={'bi-three-dots-vertical'} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={onPrintClick}>
                    <i className={'bi-printer me-3'} />Print Invoice
                </Dropdown.Item>
                <Dropdown.Item onClick={onViewPatientClick}>
                    <i className={'bi-file-person me-3'} />View Patient
                </Dropdown.Item>
                <Dropdown.Divider />
                <Restricted to={UserPermissions.ADJUST_BALANCE}>
                    <Dropdown.Item onClick={onAdjustBalance}>
                        <i className={'bi-cash-coin me-3'} />Adjust Balance
                    </Dropdown.Item>
                </Restricted>
                <Restricted to={UserPermissions.EDIT_PATIENT}>
                    <Dropdown.Item onClick={onEditPatientClick}>
                        <i className={'bi-pencil-square me-3'}/>Edit Patient Detail
                    </Dropdown.Item>
                </Restricted>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default RegistrationList;
