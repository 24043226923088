import React, {useEffect, useState} from 'react';
import {Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row} from "react-bootstrap";
import image from './../assets/3823840.jpg'
import {useFormik} from "formik";
import {useAuth} from "../contexts/authenticationContext";
import {forgotPasswordRequest, loginUser} from "../service/Reportify";
import {toast} from "../util/toast";
import {useNavigate} from "react-router-dom";
import {getApplicationLogoSvg} from "../util/host";

function Login() {
    const navigator = useNavigate();
    const {login, checkToken} = useAuth();
    const [enableForgotPassword, setEnableForgotPassword] = useState(false)
    useEffect(() => {
        checkToken();
    }, [])


    const loginRequest = async (values, {resetForm}) => {
        if (!enableForgotPassword && values.email && values.password){
            loginUser(values)
                .then(value => {
                    toast.success('Login Success!')
                    login(value.data);
                    resetForm();
                })
                .catch(reason => {
                    toast.error(reason.response.data.message)
                })
        } else if (enableForgotPassword && values.email) {
            forgotPasswordRequest(values)
                .then(value => {
                    toast.success('Reset Password Email Sent')
                    login(value.data);
                    resetForm();
                })
                .catch(reason => {
                    toast.error(reason.response.data.message)
                })
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: loginRequest,
    })

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <Row className="d-flex align-items-center justify-content-center">
                <Col sm={8} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Image src={image} fluid/>
                </Col>
                <Col sm={4} className={"p-3"} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                <Image src={getApplicationLogoSvg   ()} fluid width={'100%'}/>
                    <h4 className={'mt-5'}>{enableForgotPassword ? 'Forgot password' : 'Sign into your account'}</h4>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="my-3"
                        >
                            <Form.Control type="email" placeholder="name@example.com"
                                          name='email'
                                          value={formik.values.email}
                                          onChange={formik.handleChange}
                            />
                        </FloatingLabel>
                        {
                            enableForgotPassword ?<>
                                    <div className={'my-3 text-end'}
                                         style={{fontSize: '16px', cursor: 'pointer', color: 'blue', fontWeight: "bold"}}
                                         onClick={()=>{setEnableForgotPassword(false)}}>I Know My Password
                                    </div>
                                    <Button variant="primary" type="submit" className={'w-100'}>Reset Password</Button></>
                                : <><FloatingLabel controlId="floatingPassword" label="Password">
                                    <Form.Control type="password" placeholder="Password"
                                                  name='password'
                                                  value={formik.values.password}
                                                  onChange={formik.handleChange}
                                    />
                                </FloatingLabel>
                                <div className={'my-3 text-end'}
                                     style={{fontSize: '16px', cursor: 'pointer', color: 'blue', fontWeight: "bold"}}
                                     onClick={()=>{setEnableForgotPassword(true)}}>Forgot
                                    Password?
                                </div>
                                <Button variant="primary" type="submit" className={'w-100'}>Sign in</Button>
                            </>
                        }
                    </Form>
                    <p className="text-center fw-bold mx-3 mb-0 text-muted my-3 mb-3">OR</p>
                    <Button variant={'outline-primary'} className={'w-100'} onClick={()=>{navigator('/sign-up')}}>Don't have an account</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
