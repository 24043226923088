import axios from "axios";
import {useHistory} from "react-router-dom";
import {toast} from "../util/toast";

const axiosInstance =  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use(
    config => {
      const accessToken = localStorage.getItem('accessToken');
      let token = null;
      if(accessToken){
        token = accessToken;
      }
      const auth = token ? `Bearer ${token}` : null;
      if(auth) config.headers.Authorization = auth;
      return config;
    },
    error => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    response => {
        // Process other data as needed
        return response;
    },
    error => {
        // Handle errors
        if (error?.response?.status === 401) {
            logoutUser();
        }
        // Make sure to return a rejected Promise for the error to be caught in the component
        return Promise.reject(error);
    }
);

const logoutUser = () => {
    toast.warning('User is sign out please login again')
    window.localStorage.removeItem("accessToken")
    window.location.href = '/';
};

export default axiosInstance;
