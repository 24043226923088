import {Col, Image, Row} from "react-bootstrap";
import React from "react";
import {displayDate} from "../util/date";
import {capitalizeFirstLetter} from "../util/utility";

export const buildInvoiceHeader = (invoice, border) => {
    if (!invoice) return <></>
    const invoiceHeader = invoice.header;
    const style = {
        width: '100%',
        padding: invoiceHeader.headerPadding,
        height: invoiceHeader.headerHeight,
        border: border ? '1px solid #ccc' : '',
    }

    const headerTextSide = <Col style={{
        height: '100%',
        display: 'flex', flexDirection: 'column',
        justifyContent: 'center'
    }}>
        {
            invoiceHeader.headerTexts.map((value, index) => {
                return <div key={index}
                            style={value.style}>
                    {value.text}
                </div>
            })
        }
    </Col>

    const headerImageSide = <Col style={{
        maxWidth: invoiceHeader.headerHeight,
        maxHeight: invoiceHeader.headerHeight,
        padding: '2mm',
        textAlign: 'center'
    }}>
        <Image src={invoiceHeader.logoImageKey} className={''} style={{maxHeight: '100%', width: "auto", maxWidth: '100%'}}/>
    </Col>;

    if (invoiceHeader.logoSide === 'left' && invoiceHeader.logoImageKey !== '') {
        //left side logo
        return <Row style={style}>
            {headerImageSide}
            {headerTextSide}
        </Row>

    } else if (invoiceHeader.logoSide === 'right' && invoiceHeader.logoImageKey !== '') {
        //right side logo
        return <Row style={style}>
            {headerTextSide}
            {headerImageSide}
        </Row>
    } else {
        return headerTextSide
    }
}

export const buildPatientDetail = (patient ,invoice) => {
    return <div style={{fontSize: '14px'}}>
            <Row>
                <Col style={{ fontWeight: "bold" }} sm={2}>Invoice No.</Col>
                <Col sm={4}>: {invoice.invoiceNumber}</Col>
                <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Date & Time</Col>
                <Col sm={4}>: {displayDate(invoice.invoiceDate)}</Col>
            </Row>
            <Row>
                <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Patient UID</Col>
                <Col sm={4}>: {patient?.patientCode || ''}</Col>
                <Col className="text-start" style={{ fontWeight: "bold" }} sm={2}>Mode</Col>
                <Col sm={4}>: {invoice?.payments[0]?.mode}</Col>

            </Row>
            <Row>
                <Col style={{ fontWeight: "bold" }} sm={2}>Name</Col>
                <Col sm={4}>: {patient.title} {patient.name}</Col>
                <Col style={{ fontWeight: "bold" }} sm={2}>Doctor Name</Col>
                <Col sm={4}>: {patient?.doctorName}</Col>

            </Row>
            <Row>
                <Col style={{ fontWeight: "bold" }} sm={2}>Age/Gender</Col>
                <Col sm={4}>: {patient.age} {capitalizeFirstLetter(patient?.ageIn)} / {capitalizeFirstLetter(patient.gender)}</Col>
                <Col style={{ fontWeight: "bold" }} sm={2}>Center</Col>
                <Col sm={4}>: Main</Col>
            </Row>
            <Row>
                <Col style={{ fontWeight: "bold" }} sm={2}>Mobile/Email</Col>
                <Col sm={8}>: {patient?.mobile} / {patient?.email}</Col>

            </Row>
            <Row>
                <Col style={{ fontWeight: "bold" }} sm={2}>Address</Col>
                <Col sm={8}>: {patient?.address}</Col>

            </Row>
    </div>

}
