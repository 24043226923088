import React, {useEffect, useRef, useState} from 'react';
import {Col, ListGroup, Row, Form, Button, Badge} from "react-bootstrap";
import {getAllTestGroups} from "../../service/Reportify";
import {getQueryString} from "../../util/utility";

function TestGroupSearchBox({onItemClicked, maxHeight, defaultTestGroupList}) {
    const [searchBoxText, setSearchBoxText] = useState('');
    const [testGroups, setTestGroups] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef();
    const isInitialMount = useRef(true);
    const searchTimeoutRef = useRef(null);
    const skipEffectRef = useRef(false);

    // Consolidated fetch function that handles all data loading scenarios
    const fetchData = async (_page, resetResults = false) => {
        // Prevent fetching if already loading or no more results (unless explicitly resetting)
        if ((loading || !hasMore) && !resetResults) {
            return;
        }
        
        // Set loading state
        setLoading(true);
        
        // Reset state if needed
        if (resetResults) {
            setTestGroups([]);
            setHasMore(true);
        }
        
        // Prepare query
        const query = {
            search: searchBoxText.trim(),
            page: _page || page
        };
        
        try {
            const response = await getAllTestGroups(getQueryString(query));
            const newData = response.data.data;
            
            // Update state based on response
            if (resetResults) {
                setTestGroups(newData);
            } else {
                setTestGroups(prevData => [...prevData, ...newData]);
            }
            
            // Check if we have more data
            if (newData.length === 0) {
                setHasMore(false);
            } else {
                setPage(resetResults ? 2 : prevPage => prevPage + 1);
            }
        } catch (error) {
            console.error('Error fetching test groups:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle search button click or Enter key
    const handleSearch = (e) => {
        // If event is provided, prevent default behavior and stop propagation
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        
        if (searchBoxText.trim().length > 1 || searchBoxText.trim().length === 0) {
            setPage(1);
            fetchData(1, true);
        }
    };

    // Handle scroll for infinite loading
    const handleScroll = () => {
        const container = containerRef.current;
        if (container && !loading && hasMore) {
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollHeight - scrollTop <= clientHeight + 50) { // Added some buffer
                fetchData();
            }
        }
    };

    // Initial data load - only runs once
    useEffect(() => {
        if (isInitialMount.current) {
            fetchData(1, true);
            isInitialMount.current = false;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle searchBoxText changes with debounce
    useEffect(() => {
        // Skip the effect when searchBoxText is changed by handelItemClick
        if (skipEffectRef.current) {
            skipEffectRef.current = false;
            return;
        }

        // Only respond to empty search text after initial mount
        if (!isInitialMount.current && searchBoxText.trim().length === 0) {
            // Clear any existing timeout
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
            
            // Set a new timeout to debounce the search
            searchTimeoutRef.current = setTimeout(() => {
                handleSearch();
            }, 300);
        }
        
        // Cleanup timeout on unmount or before next effect run
        return () => {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
        };
    }, [searchBoxText]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle item click
    function handelItemClick(testGroup) {
        skipEffectRef.current = true; // Skip the searchBoxText effect
        setSearchBoxText('');
        onItemClicked(testGroup);
    }

    return (
        <div style={{border: '1px solid #ccc', borderRadius: '8px', padding: '8px' }}>
            <Row>
                <Col>
                    <Form.Control
                        className={'mb-2'}
                        type="text"
                        placeholder="Enter Name or Code"
                        name="search"
                        value={searchBoxText}
                        onChange={(e) => {
                            setSearchBoxText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation(); // Stop event from bubbling up
                                handleSearch(e);
                                return false; // Ensure no further handling
                            }
                        }}
                    />
                </Col>
                <Col sm={"auto"}>
                    <Button 
                        variant={"outline-success"} 
                        onClick={(e) => handleSearch(e)}
                        type="button" // Explicitly set type to button to prevent form submission
                    >
                        Search
                    </Button>
                </Col>
            </Row>
            <div
                ref={containerRef}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '500px',
                    maxHeight: maxHeight || '500px',
                }}
                onScroll={handleScroll}
            >
                <ListGroup as="ol" numbered>
                    {testGroups.map((testGroup, index) => (
                        <ListGroup.Item
                            key={`${testGroup._id}${index}`}
                            style={{cursor: 'pointer'}}
                            className="d-flex justify-content-between align-items-start"
                            onClick={() => handelItemClick(testGroup)}
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{testGroup.testGroupCode}</div>
                                {testGroup.name} <br/>
                                {testGroup.nameOnReport && <p className="text-muted" style={{fontSize: '14px', marginBottom: 0}}>{testGroup.nameOnReport}</p>}
                            </div>
                            {testGroup.isDefault ? <Badge bg="primary" pill>D</Badge> : ''}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                {loading && <p className={'text-center'}>Loading...</p>}
                {!hasMore && testGroups.length > 0 && <p className={'text-center'}>No more test groups</p>}
                {!loading && testGroups.length === 0 && <p className={'text-center'}>No test groups found</p>}
            </div>
        </div>
    );
}

export default TestGroupSearchBox;
