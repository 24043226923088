import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import {Field, FieldArray, Form as FormikForm, Formik} from 'formik';
import {createInvoice, getAllReferer, getPage, getPriceByTestGroupId} from "../../service/Reportify";
import {toast} from "../../util/toast";
import {Typeahead} from "react-bootstrap-typeahead";
import {getQueryString, resolveDownload, titleToGenderMap} from "../../util/utility";
import TestGroupSearchBox from "../compo/TestGroupSearchBox";
import PrintInvoiceModel from "../models/PrintInvoiceModel";

const convertToInitialValue = () => {
    return {
        invoiceDate: '',
        patient: {
            "title": '',
            "name": '',
            "age": '',
            "ageIn": 'YEARS',
            "gender": '',
            "mobile": '',
            "email": '',
            "careOf": '',
            "address": '',
            "referBy": '',
            "doctorName": '',
            "referer": '',
            "refererId": null,
            "testGroups": [],
        },
        items: [],
        payments: [{amount: 0, mode: 'Cash'}],
        discount: 0,
    }
}

function NewRegistration(props) {
    const addTestGroupRef = useRef(null);
    const [referer, setReferer] = useState([]);
    const [selectedTestGroup, setSelectedTestGroup] = useState(null);
    const [isPartnerPrice, setIsPartnerPrice] = useState(false);

    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [printInvoiceModalShow, setPrintInvoiceModalShow] = useState(false);
    const [pageSetup , setPageSetup] = useState(null);
    const [showRegistrationDate, setShowRegistrationDate] = useState(false);


    const fetchInitialData = async () => {
        const response = await getPage();
        const data = response?.data[0];
        const logoImageUrl = await resolveDownload(data?.invoice?.header?.logoImageKey)
        if (logoImageUrl){
            data.invoice.header.logoImageKey = logoImageUrl;
        }
        setPageSetup(data)
    }


    useEffect(() => {
        fetchInitialData();
        getAllReferer(getQueryString({limit: 100}))
            .then((value) => {
                const refererList = value.data?.data.map((referer)=> {
                    referer.label = `${referer.refererCode} - ${referer.name}`;
                    return referer;
                })
                setReferer(refererList);
            })
            .catch(reason => {
            })
    }, [])

    const handelOnSubmit = (values, resetForm) => {
        values.patient.refererId = values.patient.referer ? values.patient.referer._id : null;
        
        if (!values.invoiceDate) {
            delete values.invoiceDate;
        } else {
            values.invoiceDate = new Date(values.invoiceDate).toISOString();
            if(values.invoiceDate > new Date().toISOString()) {
                toast.warning("Invoice date should not be greater then current date and time")
                return
            }
        }
        
        console.log("handelSubmit", values)

        const totalAmount = 0;
        const balance = (values.items.reduce((previous, current)=>previous + current.price, 0) - values.discount) - values.payments.reduce((previous, current)=>previous + current.amount, 0);
        if (balance < 0) {
            toast.warning("Balance should not be less then zero")
            return
        }

        createInvoice(values)
            .then(value => {
                setSelectedInvoice(value.data)
                setPrintInvoiceModalShow(true)
                toast.success('Registration Complete!')
                resetForm();
                setSelectedTestGroup([]);
            })
            .catch(reason => {
                console.log("reason", reason)
                toast.error(reason.response.data.message)
            })
    }

    useEffect(() => {
        if (selectedTestGroup)
            addTestGroupRef.current.click();
    }, [selectedTestGroup])


    const handleTitleChange = (event, setFieldValue) => {
        const selectedTitle = event.target.value;
        setFieldValue('patient.title', selectedTitle)
        const selectedGender = titleToGenderMap[selectedTitle] || ''; // Default to empty string if not found
        setFieldValue('patient.gender', selectedGender);
    };

    const addItemToList = async (push, values) => {
        if (selectedTestGroup && values.items?.filter(e => e.testGroupId === selectedTestGroup._id)?.length === 0) {
            if (!selectedTestGroup._id) return
            const response = await getPriceByTestGroupId(selectedTestGroup?._id)
            const price = response.data;
            const item = {
                "testGroupId": selectedTestGroup._id,
                "code": selectedTestGroup.testGroupCode,
                "name": selectedTestGroup.name,
                "price": isPartnerPrice ? price?.priceForPartner : price?.price || '',
                "unit": 1
            }
            push(item)
            setSelectedTestGroup(null)
        }
    }

    return (
        <Container className={'my-3'}>
            <Formik
                enableReinitialize={true}
                initialValues={convertToInitialValue()}
                onSubmit={(values, {resetForm}) => {
                    handelOnSubmit(values, resetForm);
                }}
            >
                {({values, setFieldValue}) => {
                    if (!values) return

                    return <FormikForm>
                        <h5>Patient Detail</h5>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={3}>
                                <Form.Label className="d-flex align-items-center gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        label="Past Date Registration"
                                        checked={showRegistrationDate}
                                        onChange={(e) => {
                                            setShowRegistrationDate(e.target.checked);
                                            if (!e.target.checked) {
                                                setFieldValue('invoiceDate', '');
                                            }
                                        }}
                                    />
                                </Form.Label>
                                {showRegistrationDate && (
                                    <Form.Control
                                        as={Field}
                                        type="datetime-local"
                                        id={'invoiceDate'}
                                        name={'invoiceDate'}
                                    />
                                )}
                            </Form.Group>
                            <Col xs={9}></Col>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={1}>
                                <Form.Label>Title</Form.Label>
                                <Field as="select" 
                                       className="form-select" 
                                       id={'patient.title'} 
                                       name={'patient.title'}
                                       onChange={(e) => handleTitleChange(e, setFieldValue)}
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') {
                                               e.preventDefault();
                                               e.stopPropagation();
                                               
                                               // Focus on the name field
                                               const nameField = document.getElementById('patient.name');
                                               if (nameField) {
                                                   nameField.focus();
                                               }
                                               return false;
                                           }
                                       }}
                                >
                                    <option value=''>Select</option>
                                    {Object.keys(titleToGenderMap).map(key => <option key={key} value={key}>{key}</option>)}
                                </Field>
                            </Form.Group>
                            <Form.Group as={Col} xs={5}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control as={Field} 
                                              type="name" 
                                              placeholder="Enter name"
                                              id={'patient.name'} 
                                              name={'patient.name'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Focus on the age field
                                                      const ageField = document.getElementById('patient.age');
                                                      if (ageField) {
                                                          ageField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Age</Form.Label>
                                <InputGroup>
                                    <Form.Control as={Field} 
                                                  type="number" 
                                                  placeholder="Enter Age"
                                                  id={'patient.age'} 
                                                  name={'patient.age'}
                                                  onKeyDown={(e) => {
                                                      if (e.key === 'Enter') {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          
                                                          // Focus on the ageIn field
                                                          const ageInField = document.getElementById('patient.ageIn');
                                                          if (ageInField) {
                                                              ageInField.focus();
                                                          }
                                                          return false;
                                                      }
                                                  }}
                                    />
                                    <Field as="select" 
                                           className="form-select" 
                                           id={'patient.ageIn'} 
                                           name={'patient.ageIn'}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   e.preventDefault();
                                                   e.stopPropagation();
                                                   
                                                   // Focus on the gender field
                                                   const genderField = document.getElementById('patient.gender');
                                                   if (genderField) {
                                                       genderField.focus();
                                                   }
                                                   return false;
                                               }
                                           }}
                                    >
                                        <option value="YEARS">Years</option>
                                        <option value="MONTHS">Months</option>
                                        <option value="DAYS">Days</option>
                                    </Field>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group xs={3} as={Col}>
                                <Form.Label>Gender</Form.Label>
                                <Field as="select" 
                                       className="form-select" 
                                       id={'patient.gender'} 
                                       name={'patient.gender'}
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') {
                                               e.preventDefault();
                                               e.stopPropagation();
                                               
                                               // Focus on the mobile field
                                               const mobileField = document.getElementById('patient.mobile');
                                               if (mobileField) {
                                                   mobileField.focus();
                                               }
                                               return false;
                                           }
                                       }}
                                >
                                    <option value=''>Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="TRANSGENDER">Transgender</option>
                                </Field>
                            </Form.Group>
                        </Row>

                        

                        

                        <Row className="mb-3">
                            <Form.Group as={Col} xs={2}>
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control as={Field} 
                                              type="number"
                                              placeholder="Enter mobile number"
                                              id={'patient.mobile'}
                                              name={'patient.mobile'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Focus on the email field
                                                      const emailField = document.getElementById('patient.email');
                                                      if (emailField) {
                                                          emailField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control as={Field} 
                                              type="email" 
                                              placeholder="Enter email"
                                              id={'patient.email'} 
                                              name={'patient.email'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Focus on the referBy field
                                                      const referByField = document.getElementById('patient.referBy');
                                                      if (referByField) {
                                                          referByField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>


                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Refer by</Form.Label>
                                <Typeahead as={Field}
                                           labelKey={'label'}
                                           onChange={(selected) => {
                                               if (selected?.length > 0) {
                                                   setFieldValue('patient.referBy', selected[0].name)
                                                   setFieldValue('patient.referer', selected[0])
                                                   setIsPartnerPrice(selected[0]?.isPartner)
                                                   if (selected[0].type === 'DOCTOR') {
                                                       setFieldValue('patient.doctorName', selected[0].name)
                                                   }
                                               }
                                           }}
                                           onInputChange={(text, event) => {
                                               setFieldValue('patient.referBy', text)
                                           }}
                                           defaultInputValue={values.referBy}
                                           options={referer}
                                           placeholder="Choose options"
                                           id={`patient.referBy`}
                                           name={`patient.referBy`}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   e.preventDefault();
                                                   e.stopPropagation();
                                                   
                                                   // Focus on the doctorName field
                                                   const doctorNameField = document.getElementById('patient.doctorName');
                                                   if (doctorNameField) {
                                                       doctorNameField.focus();
                                                   }
                                                   return false;
                                               }
                                           }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={1}>
                                <Form.Label>Partner</Form.Label>
                                <Form.Check as={Field}
                                            type={"checkbox"}
                                            id={'isPartnerPrice'}
                                            label={"Price"}
                                            checked={isPartnerPrice}
                                            onChange={()=>{setIsPartnerPrice((pv)=>!pv)}}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Doctor Name</Form.Label>
                                <Form.Control as={Field} 
                                              type="text" 
                                              placeholder="Doctor name"
                                              id={'patient.doctorName'} 
                                              name={'patient.doctorName'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Focus on the careOf field
                                                      const careOfField = document.getElementById('patient.careOf');
                                                      if (careOfField) {
                                                          careOfField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Care of</Form.Label>
                                <Form.Control as={Field} 
                                              type="text" 
                                              placeholder="Care taker name"
                                              id={'patient.careOf'} 
                                              name={'patient.careOf'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Focus on the address field
                                                      const addressField = document.getElementById('patient.address');
                                                      if (addressField) {
                                                          addressField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={9}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as={Field} 
                                              placeholder="1234 Main St"
                                              id={'patient.address'} 
                                              name={'patient.address'}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      
                                                      // Try to focus on the first price field if it exists
                                                      if (values.items.length > 0) {
                                                          const priceField = document.getElementById('items[0].price');
                                                          if (priceField) {
                                                              priceField.focus();
                                                              return false;
                                                          }
                                                      }
                                                      
                                                      // If no price fields, focus on the discount field
                                                      const discountField = document.getElementById('discount');
                                                      if (discountField) {
                                                          discountField.focus();
                                                      }
                                                      return false;
                                                  }
                                              }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className={'my-3'} >
                            <Col sm={8}>
                                <Form.Label>Billing Items</Form.Label>
                                <Table bordered hover responsive="sm">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <FieldArray name="items">
                                        {({push, remove}) => {
                                            return <> {
                                                values.items.map((value, index) => {
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.code}</td>
                                                        <td>{value.name}</td>
                                                        <td>
                                                            <Form.Control as={Field}
                                                                          required
                                                                          className={'w-50'}
                                                                          id={`items[${index}].price`}
                                                                          name={`items[${index}].price`}
                                                                          size="sm"
                                                                          type="number"
                                                                          placeholder="Amount"
                                                                          onKeyDown={(e) => {
                                                                              if (e.key === 'Enter') {
                                                                                  e.preventDefault();
                                                                                  e.stopPropagation();
                                                                                  
                                                                                  // Find the next price field to focus on
                                                                                  const nextIndex = index + 1;
                                                                                  if (nextIndex < values.items.length) {
                                                                                      // Focus on the next price field if it exists
                                                                                      const nextField = document.getElementById(`items[${nextIndex}].price`);
                                                                                      if (nextField) {
                                                                                          nextField.focus();
                                                                                      }
                                                                                  } else {
                                                                                      // If no next item, focus on the discount field
                                                                                      const discountField = document.getElementById('discount');
                                                                                      if (discountField) {
                                                                                          discountField.focus();
                                                                                      }
                                                                                  }
                                                                                  return false;
                                                                              }
                                                                          }}
                                                            />
                                                        </td>
                                                        <td className={'text-center'}>
                                                            <button
                                                                className={'btn'}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    remove(index)
                                                                }}>
                                                                <i className="bi bi-x-lg"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                })}
                                                <button style={{display: "none"}} ref={addTestGroupRef}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addItemToList(push, values)
                                                        }}/>
                                            </>
                                        }}
                                    </FieldArray>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={3} className={'text-end'}><strong>Total</strong></td>
                                        <td>{values.items.reduce((previous, current)=>previous + current.price, 0)}</td>
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className={'text-end'}><strong>Discount</strong></td>
                                        <td>
                                            <Form.Control
                                                as={Field}
                                                className={'w-50 m-0'}
                                                id={`discount`}
                                                name={`discount`}
                                                size="sm"
                                                type="number"
                                                placeholder="Amount"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        
                                                        // Focus on the payment amount field
                                                        const paymentField = document.getElementById('payments[0].amount');
                                                        if (paymentField) {
                                                            paymentField.focus();
                                                        }
                                                        return false;
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className={'text-end'}><strong>Net Payable Amount</strong></td>
                                        <td>{values.items.reduce((previous, current)=>previous + current.price, 0) - values.discount}</td>
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className={'text-end'}><strong>Payment</strong></td>
                                        <td>
                                            <Form.Control
                                                as={Field}
                                                className={'w-50 m-0'}
                                                id={`payments[0].amount`}
                                                name={`payments[0].amount`}
                                                size="sm"
                                                type="number"
                                                placeholder="Amount"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        
                                                        // Focus on the payment mode field
                                                        const modeField = document.getElementById('payments[0].mode');
                                                        if (modeField) {
                                                            modeField.focus();
                                                        }
                                                        return false;
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className={'p-0'}>
                                            <Field as="select"
                                                   size="sm"
                                                   className={'form-select'}
                                                   id={'payments[0].mode'}
                                                   name={'payments[0].mode'}
                                                   onKeyDown={(e) => {
                                                       if (e.key === 'Enter') {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                           
                                                           // Focus on the submit button
                                                           const submitButton = document.querySelector('button[type="submit"]');
                                                           if (submitButton) {
                                                               submitButton.focus();
                                                           }
                                                           return false;
                                                       }
                                                   }}
                                            >
                                                <option value='Cash'>Cash</option>
                                                <option value="UPI">UPI</option>
                                                <option value="Card">Card</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className={'text-end'}><strong>Balance</strong></td>
                                        <td>
                                            {(values.items.reduce((previous, current)=>previous + current.price, 0) - values.discount) - values.payments.reduce((previous, current)=>previous + current.amount, 0)}
                                        </td>
                                        <td/>
                                    </tr>
                                    </tfoot>
                                </Table>
                                <Button variant="primary" type="submit">{'Save'}</Button>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Test Group List</Form.Label>
                                <TestGroupSearchBox
                                    maxHeight={'300px'}
                                    onItemClicked={(testGroup) => {
                                        setSelectedTestGroup(testGroup);
                                    }}/>
                            </Col>
                        </Row>
                    </FormikForm>
                }}
            </Formik>
            {
                pageSetup && <PrintInvoiceModel
                    show={printInvoiceModalShow}
                    onHide={() => {
                        setPrintInvoiceModalShow(false);
                    }}
                    invoice={selectedInvoice}
                    pageSetup={pageSetup}
                />
            }
        </Container>
    );
}

export default NewRegistration;
