import axiosInstance from "./Axios";

export const wakeUp = () => {
    return axiosInstance.get('/')
}

export const getPatientAndPage = (body) => {
    return axiosInstance.post('/public/report/download', body)
}

export const createTestParameter = (body) => {
    return axiosInstance.post('/test-parameter/', body)
}

export const updateTestParameter = (parameterId, body) => {
    return axiosInstance.put(`/test-parameter/${parameterId}`, body)
}

export const getTestParameterByIds = (testParametersIds) => {
    return axiosInstance.get(`/test-parameter/multiple/${testParametersIds?.join(',')}`)
}

export const deleteTestParameter = (testParametersId) => {
    return axiosInstance.delete(`/test-parameter/${testParametersId}`)
}

export const getAllTestGroups = (queryString) => {
    return axiosInstance.get(`/test-group/?${queryString}`)
}

export const createTestGroupPrice = (body) => {
    return axiosInstance.post('/test-group-price/', body)
}

export const getPriceByTestGroupIds = (testGroupIds) => {
    if (testGroupIds.length === 0){
        return []
    }
    return axiosInstance.get(`/test-group-price/multiple/${testGroupIds?.join(',')}`)
}

export const getPriceByTestGroupId = (testGroupId) => {
    return axiosInstance.get(`/test-group-price/${testGroupId}`)
}

export const syncTestGroup = () => {
    return axiosInstance.post('/test-group/sync')
}

export const createTestGroup = (body) => {
    return axiosInstance.post('/test-group/', body)
}

export const updateTestGroup = (groupId, body) => {
  return axiosInstance.put(`/test-group/${groupId}`, body);
}

export const getTestGroup = (groupId) => {
    return axiosInstance.get(`/test-group/${groupId}`);
}

export const deleteTestGroup = (groupId) => {
    return axiosInstance.delete(`/test-group/${groupId}`);
}

export const updateTestGroupFormula = (groupId, parameterId, body) => {
    return axiosInstance.put(`/test-group/${groupId}/parameter/${parameterId}/formula`, body);
}

export const getAllPatientList = (query) => {
  return axiosInstance.get(`/patient/?${query}`);
}

export const searchPatients = (queryString) => {
  return axiosInstance.get(`/patient/?${queryString}`);
}

export const getAllPatientTestGroups = (queryString) => {
    return axiosInstance.get(`/patient-test-group/?${queryString ? queryString : ''}`);
}

export const updatePatientTestGroup = (patientTestGroupId, body) => {
  return axiosInstance.put(`/patient-test-group/${patientTestGroupId}`, body);
}

export const getPatientTestGroup = (patientTestGroupId) => {
  return axiosInstance.get(`/patient-test-group/${patientTestGroupId}`);
}

export const getPatient = (patientId) => {
  return axiosInstance.get(`/patient/${patientId}`);
}

export const forgotPasswordRequest = (body) => {
    return axiosInstance.post('/auth/forgot-password-request', body);
}

export const resetPassword = (body) => {
  return axiosInstance.post('/auth/reset-password', body);
}

export const changePassword = (body) => {
    return axiosInstance.post('/auth/change-password', body);
}

export const signUpUser = (body) => {
  return axiosInstance.post('/auth/signup', body);
}

export const loginUser = (body) => {
  return axiosInstance.post('/auth/login', body);
}

export const getUserProfile = () => {
  return axiosInstance.get('/auth/profile');
}

export const createNewUser = (body) => {
    return axiosInstance.post('/auth/new-user', body);
}

export const updateUser = (body) => {
    return axiosInstance.put('/auth/update-user', body);
}

export const getAllUsers = () => {
    return axiosInstance.get('/auth/all-user');
}

export const sendVerificationEmail = () => {
  return axiosInstance.post('/auth/send-verification-email');
}

export const verifyEmail = (body) => {
  return axiosInstance.post('/auth/verify-email', body);
}

export const createTenantAndUser = (body) => {
  return axiosInstance.post('/tenant', body);
}

export const createPatient = (body) => {
  return axiosInstance.post('/patient/', body);
}
export const updatePatient = (patientId, body) => {
  return axiosInstance.put('/patient/'+patientId, body);
}

export const getAllReferer = (queryString) => {
    return axiosInstance.get(`/referer/?${queryString ? queryString : ''}`);
}

export const createReferer = (body) => {
    return axiosInstance.post(`/referer/`, body);
}

export const updateReferer = (refererId, body) => {
    return axiosInstance.patch(`/referer/${refererId}`, body);
}

export const getReferer = (refererId) => {
    return axiosInstance.get(`/referer/${refererId}`);
}

export const getHomeData = () => {
    return axiosInstance.get(`/statistics/home`);
}

export const getPatientTrend = (period = 'month') => {
    return axiosInstance.get(`/statistics/patient-trend?period=${period}`);
};

export const getReferralSources = (limit = 10) => {
    return axiosInstance.get(`/statistics/referral-sources?limit=${limit}`);
};

export const getPopularTestGroups = (limit = 7) => {
    return axiosInstance.get(`/statistics/popular-test-groups?limit=${limit}`);
};

export const getPage = () => {
    return axiosInstance.get('/page/');
}

export const createPageHeader = (body) => {
  return axiosInstance.post('/page/header', body);
}

export const createPageInvoice = (body) => {
    return axiosInstance.post('/page/invoice', body);
}

export const createPageSignature = (body) => {
    return axiosInstance.post('/page/signature', body);
}

export const createFullPage = (body) => {
    return axiosInstance.post('/page/fullPage', body);
}

export const reportPdfPageSetup = (body) => {
    return axiosInstance.post('/page/pdf-setup', body);
}

export const getSignedUrlForUpload = (fileName, body) => {
    return axiosInstance.put(`/media/${fileName||''}`, body);
}

export const getSignedUrlForDownload = (fileName) => {
    return axiosInstance.get(`/media/${fileName||''}`);
}

export const createInvoice = (body) => {
    return axiosInstance.post('/invoice/', body);
}

export const adjustInvoiceAmount = (invoiceId, body) => {
    return axiosInstance.put(`/invoice/${invoiceId}`, body);
}

export const getAllInvoice = (queryString) => {
    return axiosInstance.get(`/invoice/?${queryString}`, );
}

export const pdfReportPreview = () => {
    return axiosInstance.get('/pdf-service/preview');
}

// super admin section
export const getTenantList = () => {
    return axiosInstance.get('/super-admin/tenants');
}
