import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import {useFormik} from "formik";
import {displayDate} from "../util/date";
import {Link, useNavigate} from "react-router-dom";
import {pointer} from "../util/style";
import {getAllPatientList, searchPatients} from "../service/Reportify";
import {toast} from "../util/toast";
import {capitalizeFirstLetter, getQueryString, tableHeaderColor} from "../util/utility";
import MyPagination from "./MyPagination";
import Restricted from "../contexts/permissions/Restricted";
import {UserPermissions} from "../contexts/permissions/userPermissions";
import PageTitle from "./compo/PageTitle";

function PatientList(props) {
    const [patientList, setPatientList] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    // const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {search : ''},
        onSubmit: (values) => {searchPatient(values)}
    })

    useEffect(()=>{
        fetchData(formik.values.search, currentPage);
    },[currentPage])

    const fetchData = (search, page) => {
        getAllPatientList(getQueryString({page: page, search: search ? search : ''})).then (value => {
            setPatientList(value.data);
        }).catch(reason => {
            console.log('reason', reason)
        })
    }

    const searchPatient = (values)=> {
        if (values.search.trim().length > 1){
            fetchData(values.search, 1);
        }
    }

    function handelItemClick(patient) {
        navigate(`/patient/${patient._id}`)
    }

    const handelEditPatient = (patient) => {
        console.log("edit patient ", patient)
        navigate(`/update-patient/${patient._id}`)
    }

    return (
        <Container className={'my-3'}>
            <PageTitle title={'Patient List'} iconName={'bi-person-vcard'}/>
            <Form onSubmit={formik.handleSubmit} onChange={(e)=>{
                console.log(e.target.value)
                if (e.target.value === ''){
                    fetchData('',1)
                }
            }}>
            <Row className={'my-3'}>
                <Col>
                    <Form.Control
                        type="text"
                        placeholder="Enter Patient Id, Name, Mobile, Email"
                        name="search"
                        id={'search'}
                        onChange={formik.handleChange}
                    />
                </Col>
                <Col xs="auto">
                    <Button variant={"outline-success"} type={'submit'}>Search</Button>
                </Col>
                <Col xs="auto" hidden={true}>
                    <Link to={`/patient/create`}>
                    <Button variant={"secondary"}>Add Patient</Button>
                    </Link>
                </Col>
            </Row>
            </Form>
            <Table striped>
                <thead>
                <tr>
                    <th style={tableHeaderColor}>Patient ID</th>
                    <th style={tableHeaderColor}>Name</th>
                    <th style={tableHeaderColor}>Age - Gender</th>
                    <th style={tableHeaderColor}>Ref By</th>
                    <th style={tableHeaderColor}>Test Names</th>
                    <th style={tableHeaderColor}>Registration Time</th>
                    <th style={tableHeaderColor}></th>
                </tr>
                </thead>
                <tbody>
                {
                   patientList && patientList.data.map(patient => {
                        return (
                            <tr key={patient._id} style={pointer()} onClick={(e)=>{handelItemClick(patient)}}>

                                <td>{patient.patientCode}</td>
                                <td>{patient.title} {patient.name}</td>
                                <td>{patient.age} {patient.ageIn?.charAt(0)} - {capitalizeFirstLetter(patient.gender)}</td>
                                <td>{patient.referBy}</td>
                                <td>
                                    {renderBadges(patient.patientTestGroups)}
                                </td>
                                <td>{displayDate(patient.registrationDate)}</td>

                                <td>
                                    <Restricted to={UserPermissions.EDIT_PATIENT}>
                                        <button className={'btn p-0'} onClick={(e)=>{
                                            e.stopPropagation()
                                            handelEditPatient(patient);
                                        }}><i className={'bi-pencil-square'}/></button>
                                    </Restricted>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            {
                (formik.values.search.trim().length > 1 && patientList.length === 0) &&
                <h5 style={{textAlign: "center"}}>No patient found with {formik.values.search}</h5>
            }
            {
                patientList && <MyPagination currentPage={patientList.pageInfo.currentPage} pageLimit={patientList.pageInfo.limit} totalItem={patientList.pageInfo.totalItem} onPageClick={(page)=>{setCurrentPage(page)}}/>
            }

        </Container>
    );
}

const renderBadges = (patientTestGroups) => {
    const noOfItem = 2;
    const numRows = Math.ceil(patientTestGroups.length / noOfItem);

    const badgeRows = [];
    let startIndex = 0;

    for (let i = 0; i < numRows; i++) {
        const endIndex = startIndex + noOfItem;
        const rowBadges = patientTestGroups.slice(startIndex, endIndex);

        const row = (
            <Row key={i}>
                {rowBadges.map((item, index) => (
                    <Col key={index} style={{maxWidth: "fit-content"}}>
                        <Badge bg={item.isReportCompleted ? 'success' : 'secondary'}>{item.testGroupCode} - {item.name}</Badge>
                    </Col>
                ))}
            </Row>
        );

        badgeRows.push(row);
        startIndex = endIndex;
    }

    return badgeRows;
};

export default PatientList;
