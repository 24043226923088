import React, {useRef} from 'react';
import {Button, Col, Container, Row, Form, Table, InputGroup, DropdownButton} from "react-bootstrap";
import {Formik, Field, Form as FormikForm, FieldArray} from 'formik';
import TestGroupList from "./TestGroupList";
import {useEffect, useState} from "react";
import {createPatient, getAllReferer, getAllTestGroups, getPatient, updatePatient} from "../service/Reportify";
import {toast} from "../util/toast";
import {useParams} from "react-router-dom";
import {Typeahead} from "react-bootstrap-typeahead";
import {getQueryString, titleToGenderMap} from "../util/utility";
import TestGroupSearchBox from "./compo/TestGroupSearchBox";

const convertToInitialValue = (patient) => {
    console.log("patient", patient)
    return {
        "title": patient?.title || '',
        "name": patient?.name || '',
        "age": patient?.age || '',
        "ageIn": patient?.ageIn || 'YEARS',
        "gender": patient?.gender || '',
        "mobile": patient?.mobile || '',
        "email": patient?.email || '',
        "careOf": patient?.careOf || '',
        "address": patient?.address || '',
        "referBy": patient?.referBy || '',
        "doctorName": patient?.doctorName || '',
        "referer": patient?.referer || '',
        "refererId": null,
        "testGroups": patient?.testGroups || [],
    }
}

function CreatePatient(props) {
    const {patientId} = useParams();
    const addTestGroupRef = useRef(null);
    const [patient, setPatient] = useState(null);
    const [mainTestGroups, setMainTestGroups] = useState([]);
    const [testGroups, setTestGroups] = useState([]);
    const [referer, setReferer] = useState([]);
    const [selectedTestGroup, setSelectedTestGroup] = useState(null);

    useEffect(() => {
        getAllReferer(getQueryString({limit: 100})).then((value) => {
            setReferer(value.data.data);
        }).catch(reason => {

        })

        if (patientId) {
            getPatient(patientId).then(value => {
                setPatient(value.data)
            }).catch(reason => {
                console.log("Reason ", reason)
                toast.error(reason.response.data.message)
            })
        }


        getAllTestGroups()
            .then(value => {
                setMainTestGroups(value.data.data)
                setTestGroups(value.data.data)
            })
            .catch(reason => {
                console.log(reason)
            })
    }, [])

    const handelOnSubmit = (values, resetForm) => {
        if (!patientId && values.testGroups.length === 0) {
            toast.warning('Not allowed without Test Group')
            return;
        }
        values.testGroupIds = values.testGroups.map(value => {
            return value._id
        })
        values.refererId = values.referer ? values.referer._id : null;
        if (patientId) {
            //update patient
            updatePatient(patientId, values)
                .then(value => {
                    toast.success('Patient Updated!')
                    setSelectedTestGroup([]);
                })
                .catch(reason => {
                    console.log("reason", reason)
                    toast.error(reason.response.data.message)
                })
        } else {
            //create new patient
            createPatient(values)
                .then(value => {
                    toast.success('Patient Created!')
                    resetForm();
                    setSelectedTestGroup([]);
                })
                .catch(reason => {
                    console.log("reason", reason)
                    toast.error(reason.response.data.message)
                })
        }

    }


    function onChangeHandelSearch(e) {
        const value = e.target.value;
        if (value) {
            const temp = mainTestGroups.filter(tg => {
                const lv = value.toLowerCase();
                return (tg.testGroupCode.toLowerCase().includes(lv) || tg.name.toLowerCase().includes(lv))
            })
            setTestGroups(temp)
        } else {
            setTestGroups(mainTestGroups)
        }
    }

    function onItemClick(value) {
        // const isDuplicate = selectedTestGroup.some((item) => item._id === value._id);
        // !isDuplicate && setSelectedTestGroup([...selectedTestGroup, value])
        setSelectedTestGroup(value);
        setTimeout(() => {
            addTestGroupRef.current.click();
        }, 10)
    }

    function onRemoveItem(value, index) {
        console.log(value)
        const tempList = selectedTestGroup.map(value1 => {
            if (value._id !== value1._id) {
                return value1
            }
        })
        setSelectedTestGroup(tempList)
    }

    const handleTitleChange = (event, setFieldValue) => {
        const selectedTitle = event.target.value;
        setFieldValue('title', selectedTitle)
        const selectedGender = titleToGenderMap[selectedTitle] || ''; // Default to empty string if not found
        setFieldValue('gender', selectedGender);
    };

    return (
        <Container className={'my-3'}>
            <Formik
                enableReinitialize={true}
                initialValues={convertToInitialValue(patient)}
                onSubmit={(values, {resetForm}) => {
                    handelOnSubmit(values, resetForm);
                }}
            >
                {({values, setFieldValue}) => {
                    if (patientId && !values) return

                    return <FormikForm>
                        <h5>Add New Patient</h5>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={1}>
                                <Form.Label>Title</Form.Label>
                                <Field as="select" className="form-select" id={'title'} name={'title'}
                                       onChange={(e) => handleTitleChange(e, setFieldValue)}>
                                    <option value=''>Select</option>
                                    { Object.keys(titleToGenderMap).map(key=><option value={key}>{key}</option>) }
                                </Field>
                            </Form.Group>
                            <Form.Group as={Col} xs={5}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control as={Field} type="name" placeholder="Enter name"
                                              id={'name'} name={'name'}
                                />
                            </Form.Group>

                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Age</Form.Label>
                                <InputGroup>
                                    <Form.Control as={Field} type="number" placeholder="Enter Age"
                                                  id={'age'} name={'age'}/>
                                    <Field as="select" className="form-select" id={'ageIn'} name={'ageIn'}>
                                        <option value="YEARS">Years</option>
                                        <option value="MONTHS">Months</option>
                                        <option value="DAYS">Days</option>
                                    </Field>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group xs={3} as={Col}>
                                <Form.Label>Gender</Form.Label>
                                <Field as="select" className="form-select" id={'gender'} name={'gender'}>
                                    <option value=''>Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="TRANSGENDER">Transgender</option>
                                </Field>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control as={Field} type="number" placeholder="Enter mobile number"
                                              id={'mobile'} name={'mobile'}/>
                            </Form.Group>

                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control as={Field} type="email" placeholder="Enter email"
                                              id={'email'} name={'email'}/>
                            </Form.Group>


                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Refer by</Form.Label>
                                <Typeahead as={Field}
                                           labelKey={'name'}
                                           onChange={(selected) => {
                                               if (selected?.length > 0) {
                                                   setFieldValue('referBy', selected[0].name)
                                                   setFieldValue('referer', selected[0])
                                                   if (selected[0].type === 'DOCTOR'){
                                                       setFieldValue('doctorName', selected[0].name)
                                                   }
                                               }
                                           }}
                                           onInputChange={(text, event) => {
                                               setFieldValue('referBy', text)
                                           }}
                                            // defaultSelected={referer?.filter((refr)=>refr._id === values.referer)}
                                           defaultInputValue={values.referBy}
                                           options={referer}
                                           placeholder="Choose options"
                                           id={`referBy`}
                                           name={`referBy`}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Doctor Name</Form.Label>
                                <Form.Control as={Field} type="text" placeholder="Doctor name"
                                              id={'doctorName'} name={'doctorName'}/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3" >
                            <Form.Group as={Col} xs={3}>
                                <Form.Label>Care of</Form.Label>
                                <Form.Control as={Field} type="text" placeholder="Care taker name"
                                              id={'careOf'} name={'careOf'}/>
                            </Form.Group>
                            <Form.Group as={Col} xs={9}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as={Field} placeholder="1234 Main St"
                                              id={'address'} name={'address'}/>
                            </Form.Group>
                        </Row>

                        <div hidden={!!patientId}>
                            <h5>Test Group list</h5>
                            <Row className={'my-3'}>
                                <Col sm={8}>
                                    <Table bordered hover responsive="sm">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <FieldArray name="testGroups">
                                            {({push, remove}) => {
                                                return <> {
                                                    values.testGroups.map((value, index) => {
                                                        return <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.testGroupCode}</td>
                                                            <td>{value.name}</td>
                                                            <td>
                                                                <Form.Control disabled={true} className={'w-25'}
                                                                              size="sm"
                                                                              type="text" placeholder="Amount"/>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={'btn'}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        remove(index)
                                                                    }}>
                                                                    <i className="bi bi-x-lg"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    <button style={{display: "none"}} ref={addTestGroupRef}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (values.testGroups.filter(e => e._id === selectedTestGroup._id)?.length === 0) {
                                                                    push(selectedTestGroup)
                                                                }
                                                            }}/>
                                                </>
                                            }}
                                        </FieldArray>
                                        </tbody>
                                    </Table>

                                </Col>
                                <Col sm={4}>
                                    <TestGroupSearchBox onItemClicked={(testGroup) => {
                                        onItemClick(testGroup)
                                    }}/>
                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className={'mb-2'}*/}
                                    {/*            type="text"*/}
                                    {/*            placeholder="Search Test Group"*/}
                                    {/*            name="search"*/}
                                    {/*            onChange={onChangeHandelSearch}*/}
                                    {/*        />*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <TestGroupList testGroups={testGroups} onItemClick={(testGroup)=>{ onItemClick(testGroup)}} />*/}
                                    {/*</Row>*/}
                                </Col>
                            </Row>
                        </div>
                        <Button variant="primary" type="submit">{patientId ? 'Update' : 'Save'}</Button>
                    </FormikForm>
                }}
            </Formik>
        </Container>
    );
}

export default CreatePatient;
