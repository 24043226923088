import reportifyLogo from "../assets/reportify/logo.png";
import labLogo from "../assets/reportifypro.svg";

export const getHostName = () => {
    const hostname = window.location.hostname; // e.g., "www.app.domain.com"
    const parts = hostname.split('.'); // Split by "."
    
    if (parts.length >= 2) {
      return parts[parts.length - 2]; // Get second last part
    }
    return hostname; // Return as is if not a subdomain
  };

  export const getApplicationName = () => {
    return 'Reportify Pro';
  }

  export const getApplicationLogo = () => {
    return reportifyLogo;
  }

  export const getApplicationLogoSvg = () => {
    return labLogo;
  }
  