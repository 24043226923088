import React, {useState} from 'react';
import {Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Stack} from "react-bootstrap";
import image from './../assets/3823840.jpg'
import {useFormik} from "formik";
import * as Yup from 'yup';
import {signUpUser} from "../service/Reportify";
import {toast} from "../util/toast";
import {useNavigate} from "react-router-dom";
import {getApplicationLogoSvg} from "../util/host";
import {useAuth} from "../contexts/authenticationContext";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    name: Yup.string()
        .required('Diagnostic center name is required')
        .min(3, 'Name must be at least 3 characters'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .matches(/[0-9]/, 'Password must contain at least 1 number')
        .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character'),
    confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    subscriptionPlan: Yup.string()
        .required('Subscription plan is required')
});

const initialValues = {
    email: '',
    name: '',
    subscriptionPlan: 'TRIAL',
    password: '',
    confirmPassword: ''
}

function SignUp() {
    const navigator = useNavigate();
    const [isTermChecked, setTermChecked] = useState(false);
    const {login, checkToken} = useAuth();
    
    const signUpRequestCreateTenant = async (values, {resetForm}) => {
        signUpUser(values)
            .then(value => {
                login(value.data);
                resetForm();
                toast.success('Registration successful')
            })
            .catch(reason => {
                toast.error(reason.response.data.message)
            })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: signUpRequestCreateTenant,
    })

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <Row className="d-flex align-items-center justify-content-center">
                <Col sm={8} style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    <Image src={image} fluid height={'100%'}/>
                </Col>
                <Col sm={4} className={"p-3"} style={{
                    height: '100%',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <Image src={getApplicationLogoSvg()} fluid width={'100%'}/>
                    <h4 className={'mt-5'}>Register your diagnostic center</h4>
                    <Form onSubmit={formik.handleSubmit}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Diagnostic center name"
                            className="my-3">
                            <Form.Control 
                                type="text" 
                                placeholder="SRL Diagnostic"
                                name='name'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                isInvalid={formik.touched.name && formik.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Business email address"
                            className="my-3"
                        >
                            <Form.Control 
                                type="email" 
                                placeholder="name@example.com"
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.email && formik.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.email}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel 
                            controlId="floatingPassword" 
                            label="Password"
                            className="my-3"
                        >
                            <Form.Control 
                                type="password" 
                                placeholder="Password"
                                name='password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.password && formik.errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.password}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel 
                            controlId="floatingPassword" 
                            label="Confirm Password"
                            className="my-3"
                        >
                            <Form.Control 
                                type="password" 
                                placeholder="Confirm Password"
                                name='confirmPassword'
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.confirmPassword}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingPassword" label="Plan" hidden={true}>
                            <Form.Select 
                                disabled={true}
                                onChange={formik.handleChange}
                                name='subscriptionPlan'
                                value={formik.values.subscriptionPlan}
                            >
                                <option value="DEMO">Demo</option>
                            </Form.Select>
                        </FloatingLabel>

                        <Form.Check className={'my-3'}>
                            <Form.Check.Input 
                                type="checkbox"
                                id="termsCheckbox"
                                checked={isTermChecked}
                                onChange={()=>{setTermChecked(!isTermChecked)}}
                            />
                            <Form.Check.Label htmlFor="termsCheckbox">
                                I accept the <a href="https://www.reportifypro.com/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://www.reportifypro.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </Form.Check.Label>
                        </Form.Check>
                        <Button 
                            variant="primary" 
                            type="submit" 
                            disabled={!isTermChecked || !formik.isValid || !formik.dirty} 
                            className={'w-100'}
                        >
                            Register
                        </Button>
                    </Form>
                    <p className="text-center fw-bold mx-3 mb-0 text-muted my-3">OR</p>
                    <Button 
                        variant={'outline-primary'} 
                        className={'w-100 my-3'} 
                        onClick={()=>{navigator('/')}}
                    >
                        Already have account
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default SignUp;
