import React from 'react';
import PropTypes from 'prop-types';
import {ListGroup} from "react-bootstrap";

TestGroupList.propTypes = {
    testGroups: PropTypes.array,
    onItemClick: PropTypes.func,
};

function TestGroupList(props) {
    return (
        <div>
            <div style={{ maxHeight: '500Px', overflowY: 'auto' }}>
            <ListGroup>
                {
                    props.testGroups.map(testGroup => {
                        return <ListGroup.Item key={testGroup.testGroupCode} as="li" style={{ cursor: 'pointer' }} onClick={() => { props.onItemClick(testGroup)}
                        }><span className={'fw-bold'}>{testGroup.testGroupCode}</span> {testGroup.name}</ListGroup.Item>
                    })
                }

            </ListGroup>
            </div>
        </div>
    );
}

export default TestGroupList;
