import React from 'react';
import {Table} from 'react-bootstrap';
import {displayDate} from "../util/date";
import {capitalizeFirstLetter} from "../util/utility";

const labelStyle = {
    width: '30%',
    textAlign: 'right',
    fontWeight: 'bold',
};
function PatientDetailComponent({ patient }) {
    return (
        patient &&
            <Table striped bordered>
                    <tbody>
                    <tr>
                        <th style={labelStyle}>Patient Id:</th>
                        <td>{patient.patientCode}</td>
                        <th style={labelStyle}>Name:</th>
                        <td>{patient.title} {patient.name}</td>
                    </tr>

                    <tr>
                        <th style={labelStyle}>Age:</th>
                        <td>{patient.age} {capitalizeFirstLetter(patient?.ageIn)}</td>
                        <th style={labelStyle}>Gender:</th>
                        <td>{patient.gender}</td>
                    </tr>
                    <tr>
                        <th style={labelStyle}>Mobile:</th>
                        <td>{patient.mobile}</td>
                        <th style={labelStyle}>Email:</th>
                        <td>{patient.email}</td>
                    </tr>
                    <tr>
                        <th style={labelStyle}>Address:</th>
                        <td>{patient.address}</td>
                        <th style={labelStyle}>Date Time:</th>
                        <td>{displayDate(patient.registrationDate)}</td>
                    </tr>
                    <tr>
                        <th style={labelStyle}>Doctor Name:</th>
                        <td>{patient.doctorName}</td>
                        <th style={labelStyle}>Referred By:</th>
                        <td>{patient.referBy}</td>
                    </tr>
                    </tbody>
                </Table>
    );
}

export default PatientDetailComponent;
