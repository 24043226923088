import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useAuth } from '../contexts/authenticationContext';
import { getApplicationName } from '../util/host';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import { sendVerificationEmail } from '../service/Reportify';
import { toast } from '../util/toast';

const Layout = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const appName = getApplicationName();
    const hasVerificationAlert = isLoggedIn && user && !user.isEmailVerified;
    const [isVerifying, setIsVerifying] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    
    useEffect(() => {
        // Check sidebar collapsed state from localStorage
        const savedState = localStorage.getItem('sidebarCollapsed');
        if (savedState !== null) {
            setIsSidebarCollapsed(savedState === 'true');
        }
        
        // Listen for changes to localStorage
        const handleStorageChange = (e) => {
            if (e.key === 'sidebarCollapsed') {
                setIsSidebarCollapsed(e.newValue === 'true');
            }
        };
        
        window.addEventListener('storage', handleStorageChange);
        
        // Also listen for direct changes to localStorage from this window
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function(key, value) {
            const event = new Event('storage');
            event.key = key;
            event.newValue = value;
            window.dispatchEvent(event);
            originalSetItem.apply(this, arguments);
        };
        
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            localStorage.setItem = originalSetItem;
        };
    }, []);

    const handleSendVerificationEmail = () => {
        setIsVerifying(true);
        sendVerificationEmail()
            .then(() => {
                toast.success('Verification email sent successfully!');
            })
            .catch(error => {
                toast.error('Failed to send verification email. Please try again.');
                console.error('Error sending verification email:', error);
            })
            .finally(() => {
                setIsVerifying(false);
            });
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            {/* Email Verification Alert - Updated with padding for mobile */}
            {hasVerificationAlert && (
                <div className="verification-alert-container">
                    <Alert variant="warning" className="mb-0 text-center py-2">
                        <Container className="d-flex justify-content-between align-items-center">
                            <div className="d-none d-sm-block">
                                <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                Your email address is not verified. Please verify your email to ensure full access to all features.
                            </div>
                            <div className="d-block d-sm-none">
                                <i className="bi bi-exclamation-triangle-fill me-2"></i>
                                Email not verified. Please verify.
                            </div>
                            <div>
                                <Button 
                                    variant="outline-primary" 
                                    size="sm" 
                                    className="me-2"
                                    onClick={handleSendVerificationEmail}
                                    disabled={isVerifying}
                                >
                                    {isVerifying ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                            Sending...
                                        </>
                                    ) : (
                                        'Resend Verification Email'
                                    )}
                                </Button>
                            </div>
                        </Container>
                    </Alert>
                </div>
            )}
            
            {/* Sidebar */}
            {isLoggedIn && <Sidebar appName={appName} hasVerificationAlert={hasVerificationAlert} />}
            
            {/* Main Content - Updated to handle collapsed sidebar */}
            <main 
                className={`flex-grow-1 ${isLoggedIn ? 'content-with-sidebar' : ''} ${hasVerificationAlert ? 'has-verification-alert' : ''} ${isSidebarCollapsed ? 'sidebar-collapsed-content' : ''}`}
                style={{ 
                    overflow: 'hidden',
                    marginLeft: isLoggedIn ? (window.innerWidth >= 768 ? (isSidebarCollapsed ? '70px' : '250px') : '0') : '0'
                }}
            >
                {/* For desktop view */}
                <div className={isLoggedIn ? 'd-none d-md-block' : ''}>
                    <div className={`container-fluid px-4 py-3 ${hasVerificationAlert ? 'pt-5' : ''}`}>
                        {children}
                    </div>
                </div>
                
                {/* For mobile view - Updated with additional padding */}
                <div className={isLoggedIn ? 'd-block d-md-none' : ''}>
                    <div className={`container-fluid px-4 py-5 ${hasVerificationAlert ? 'pt-5 mt-4' : ''}`}>
                        {children}
                    </div>
                </div>
                
                {/* For non-logged in users */}
                {!isLoggedIn && (
                    <div className="container-fluid px-4">
                        {children}
                    </div>
                )}
            </main>
        </div>
    );
};

export default Layout; 