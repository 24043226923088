import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner, Button } from 'react-bootstrap';
import { 
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, 
  PieChart, Pie, Cell, BarChart, Bar, Legend
} from 'recharts';
import { getPatientTrend, getReferralSources, getPopularTestGroups } from '../service/Reportify';
import { toast } from '../util/toast';
import PageTitle from './compo/PageTitle';

// Custom shape for the bar chart
const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

// Colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

// Custom tooltips
const PatientTrendTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <p>{`Patients: ${payload[0].value}`}</p>
        <p>{`Reports: ${payload[1].value}`}</p>
      </div>
    );
  }
  return null;
};

const ReferralTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    console.log("payload : ", payload);
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].name}`}</p>
        <p>{`Patients: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const TestGroupTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p>{`Tests: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

// Helper functions for local storage caching
const isDataExpired = (timestamp) => {
  if (!timestamp) return true;
  
  const storedDate = new Date(timestamp);
  const currentDate = new Date();
  
  // Check if the stored date is from a different day than the current date
  return storedDate.toDateString() !== currentDate.toDateString();
};

const getStoredData = (key) => {
  try {
    const storedData = localStorage.getItem(key);
    if (!storedData) return null;
    
    const { data, timestamp } = JSON.parse(storedData);
    
    if (isDataExpired(timestamp)) {
      localStorage.removeItem(key);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error);
    return null;
  }
};

const storeData = (key, data) => {
  try {
    const dataToStore = {
      data,
      timestamp: new Date().toISOString()
    };
    localStorage.setItem(key, JSON.stringify(dataToStore));
  } catch (error) {
    console.error(`Error storing ${key} in localStorage:`, error);
  }
};

// Custom tick formatter for X-axis to handle long text
const CustomXAxisTick = ({ x, y, payload }) => {
  const maxCharsPerLine = 12; // Maximum characters per line
  const maxLines = 2; // Maximum number of lines to show
  
  let displayText = payload.value;
  let lines = [];
  
  // If text is longer than maxCharsPerLine, try to split it into multiple lines
  if (displayText.length > maxCharsPerLine) {
    // Try to split at a space to avoid breaking words
    let currentLine = '';
    const words = displayText.split(' ');
    
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      
      if ((currentLine + ' ' + word).length <= maxCharsPerLine || currentLine === '') {
        currentLine = currentLine === '' ? word : currentLine + ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
        
        // If we've reached the maximum number of lines, stop processing
        if (lines.length >= maxLines - 1) {
          // Add the rest of the words with ellipsis
          if (i < words.length - 1) {
            currentLine += '...';
          }
          break;
        }
      }
    }
    
    // Add the last line
    if (currentLine) {
      lines.push(currentLine);
    }
  } else {
    lines = [displayText];
  }
  
  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * 12} // Line height
          dy={10} // Initial vertical offset
          textAnchor="middle"
          fill="#666"
          fontSize="11px"
          title={payload.value} // Original text as tooltip
        >
          {line}
        </text>
      ))}
    </g>
  );
};

const Dashboard = () => {
  const [patientTrendData, setPatientTrendData] = useState([]);
  const [referralData, setReferralData] = useState([]);
  const [testGroupData, setTestGroupData] = useState([]);
  const [loading, setLoading] = useState({
    patientTrend: true,
    referral: true,
    testGroup: true
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastUpdated, setLastUpdated] = useState({
    patientTrend: null,
    referral: null,
    testGroup: null
  });

  // Function to format the timestamp
  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Never';
    
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };
  
  // Function to get the most recent update timestamp
  const getMostRecentUpdate = () => {
    const timestamps = [
      lastUpdated.patientTrend,
      lastUpdated.referral,
      lastUpdated.testGroup
    ].filter(Boolean); // Filter out null/undefined values
    
    if (timestamps.length === 0) return null;
    
    // Find the most recent timestamp
    return new Date(Math.max(...timestamps.map(t => new Date(t).getTime()))).toISOString();
  };

  // Function to fetch all dashboard data
  const fetchAllDashboardData = async (forceRefresh = false) => {
    setIsRefreshing(true);
    let hasErrors = false;
    
    // Fetch patient trend data
    const fetchPatientTrend = async () => {
      try {
        setLoading(prev => ({ ...prev, patientTrend: true }));
        
        // Check if we have cached data and not forcing refresh
        if (!forceRefresh) {
          const storedData = localStorage.getItem('patientTrendData');
          if (storedData) {
            const { data, timestamp } = JSON.parse(storedData);
            if (!isDataExpired(timestamp)) {
              setPatientTrendData(data);
              setLastUpdated(prev => ({ ...prev, patientTrend: timestamp }));
              setLoading(prev => ({ ...prev, patientTrend: false }));
              return;
            }
          }
        }
        
        // If no cached data, expired, or forcing refresh, fetch from API
        const response = await getPatientTrend();
        const newData = response.data;
        setPatientTrendData(newData);
        
        // Store the new data in localStorage with timestamp
        const timestamp = new Date().toISOString();
        storeData('patientTrendData', newData);
        setLastUpdated(prev => ({ ...prev, patientTrend: timestamp }));
      } catch (error) {
        console.error('Error fetching patient trend data:', error);
        toast.error('Failed to load patient trend data');
        hasErrors = true;
      } finally {
        setLoading(prev => ({ ...prev, patientTrend: false }));
      }
    };

    // Fetch referral sources data
    const fetchReferralSources = async () => {
      try {
        setLoading(prev => ({ ...prev, referral: true }));
        
        // Check if we have cached data and not forcing refresh
        if (!forceRefresh) {
          const storedData = localStorage.getItem('referralData');
          if (storedData) {
            const { data, timestamp } = JSON.parse(storedData);
            if (!isDataExpired(timestamp)) {
              setReferralData(data);
              setLastUpdated(prev => ({ ...prev, referral: timestamp }));
              setLoading(prev => ({ ...prev, referral: false }));
              return;
            }
          }
        }
        
        // If no cached data, expired, or forcing refresh, fetch from API
        const response = await getReferralSources();
        const newData = response.data;
        setReferralData(newData);
        
        // Store the new data in localStorage with timestamp
        const timestamp = new Date().toISOString();
        storeData('referralData', newData);
        setLastUpdated(prev => ({ ...prev, referral: timestamp }));
      } catch (error) {
        console.error('Error fetching referral data:', error);
        toast.error('Failed to load referral data');
        hasErrors = true;
      } finally {
        setLoading(prev => ({ ...prev, referral: false }));
      }
    };

    // Fetch popular test groups data
    const fetchPopularTestGroups = async () => {
      try {
        setLoading(prev => ({ ...prev, testGroup: true }));
        
        // Check if we have cached data and not forcing refresh
        if (!forceRefresh) {
          const storedData = localStorage.getItem('testGroupData');
          if (storedData) {
            const { data, timestamp } = JSON.parse(storedData);
            if (!isDataExpired(timestamp)) {
              setTestGroupData(data);
              setLastUpdated(prev => ({ ...prev, testGroup: timestamp }));
              setLoading(prev => ({ ...prev, testGroup: false }));
              return;
            }
          }
        }
        
        // If no cached data, expired, or forcing refresh, fetch from API
        const response = await getPopularTestGroups();
        const newData = response.data;
        setTestGroupData(newData);
        
        // Store the new data in localStorage with timestamp
        const timestamp = new Date().toISOString();
        storeData('testGroupData', newData);
        setLastUpdated(prev => ({ ...prev, testGroup: timestamp }));
      } catch (error) {
        console.error('Error fetching test group data:', error);
        toast.error('Failed to load test group data');
        hasErrors = true;
      } finally {
        setLoading(prev => ({ ...prev, testGroup: false }));
      }
    };

    // Execute all fetch functions
    await Promise.all([
      fetchPatientTrend(),
      fetchReferralSources(),
      fetchPopularTestGroups()
    ]);
    
    setIsRefreshing(false);
    
    // Show appropriate message if this was a manual refresh
    if (forceRefresh) {
      if (hasErrors) {
        toast.warning('Dashboard partially refreshed with some errors');
      } else {
        toast.success('Dashboard data refreshed successfully!');
      }
    }
  };

  // Handle manual refresh
  const handleRefresh = () => {
    // Force refresh by clearing localStorage and fetching new data
    localStorage.removeItem('patientTrendData');
    localStorage.removeItem('referralData');
    localStorage.removeItem('testGroupData');
    
    toast.info('Refreshing dashboard data...');
    fetchAllDashboardData(true);
  };

  useEffect(() => {
    // Initial data fetch
    fetchAllDashboardData();
  }, []);

  return (
    <Container fluid className="py-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle iconName="bi bi-graph-up" title="Dashboard" />
        <div className="d-flex align-items-center">
          {!isRefreshing && (
            <small className="dashboard-last-updated me-3">
              Last updated: {formatLastUpdated(getMostRecentUpdate())}
            </small>
          )}
          <Button 
            variant="outline-primary" 
            onClick={handleRefresh} 
            disabled={isRefreshing}
            className="d-flex align-items-center"
          >
            {isRefreshing ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                Refreshing...
              </>
            ) : (
              <>
                <i className="bi bi-arrow-clockwise me-2"></i>
                Refresh Data
              </>
            )}
          </Button>
        </div>
      </div>
      
      {/* Patient Trends Chart */}
      <Card className="mb-4 shadow-sm">
        <Card.Header className="bg-primary text-white">
          <h5 className="mb-0">Patient Trends</h5>
        </Card.Header>
        <Card.Body>
          {loading.patientTrend ? (
            <div className="text-center py-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={patientTrendData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<PatientTrendTooltip />} />
                <Legend />
                <Area 
                  type="monotone" 
                  dataKey="pt" 
                  stackId="1"
                  stroke="#8884d8" 
                  fill="#8884d8" 
                  name="Patients"
                />
                <Area 
                  type="monotone" 
                  dataKey="ptg" 
                  stackId="1"
                  stroke="#82ca9d" 
                  fill="#82ca9d" 
                  name="Reports"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </Card>

      <Row>
        {/* Referral Sources Chart */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm h-100">
            <Card.Header className="bg-success text-white">
              <h5 className="mb-0">Referral Sources</h5>
            </Card.Header>
            <Card.Body>
              {loading.referral ? (
                <div className="text-center py-5">
                  <Spinner animation="border" variant="success" />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={referralData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      dataKey="value"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                      {referralData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip content={<ReferralTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Popular Test Groups Chart */}
        <Col md={6}>
          <Card className="mb-4 shadow-sm h-100">
            <Card.Header className="bg-info text-white">
              <h5 className="mb-0">Popular Test Groups</h5>
            </Card.Header>
            <Card.Body>
              {loading.testGroup ? (
                <div className="text-center py-5">
                  <Spinner animation="border" variant="info" />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={testGroupData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                    barSize={30}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      tick={<CustomXAxisTick />}
                      height={35}
                      interval={0}
                    />
                    <YAxis />
                    <Tooltip content={<TestGroupTooltip />} />
                    <Legend />
                    <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} name="Tests" />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard; 