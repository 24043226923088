import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Alert, Button, Spinner, Card } from 'react-bootstrap';
import { verifyEmail } from '../service/Reportify';
import { toast } from '../util/toast';
import { useAuth } from '../contexts/authenticationContext';

function VerifyEmail() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const [verificationStatus, setVerificationStatus] = useState('verifying'); // 'verifying', 'success', 'failed'
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const verifyUserEmail = async () => {
            if (!token) {
                setVerificationStatus('failed');
                setErrorMessage('No verification token provided');
                setIsLoading(false);
                return;
            }

            try {
                const response = await verifyEmail({ token });
                setVerificationStatus('success');
                toast.success('Email verified successfully!');
                
                // No need to check authentication status since the API is public
            } catch (error) {
                console.error('Verification error:', error);
                setVerificationStatus('failed');
                const message = error.response?.data?.message || 'Email verification failed. Please try again.';
                setErrorMessage(message);
                toast.error(message);
            } finally {
                setIsLoading(false);
            }
        };

        verifyUserEmail();
    }, [token]);

    const handleGoToHome = () => {
        navigate('/dashboard');
    };

    const handleGoToLogin = () => {
        navigate('/');
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
            <Card className="shadow-sm" style={{ maxWidth: '500px', width: '100%' }}>
                <Card.Body className="p-4 text-center">
                    <h3 className="mb-4">Email Verification</h3>
                    
                    {isLoading && (
                        <div className="text-center my-4">
                            <Spinner animation="border" variant="primary" />
                            <p className="mt-3">Verifying your email...</p>
                        </div>
                    )}

                    {!isLoading && verificationStatus === 'success' && (
                        <>
                            <Alert variant="success">
                                <i className="bi bi-check-circle-fill me-2"></i>
                                Your email has been successfully verified!
                            </Alert>
                            <p className="mb-4">Thank you for verifying your email address. You now have full access to all features.</p>
                            {isLoggedIn ? (
                                <Button variant="primary" onClick={handleGoToHome}>
                                    Go to Dashboard
                                </Button>
                            ) : (
                                <Button variant="primary" onClick={handleGoToLogin}>
                                    Go to Login
                                </Button>
                            )}
                        </>
                    )}

                    {!isLoading && verificationStatus === 'failed' && (
                        <>
                            <Alert variant="danger">
                                <i className="bi bi-x-circle-fill me-2"></i>
                                Email verification failed
                            </Alert>
                            <p className="mb-4">
                                {errorMessage || "We couldn't verify your email address. The verification link may have expired or is invalid."}
                            </p>
                            <div className="d-flex justify-content-center gap-3">
                                <Button variant="outline-secondary" onClick={handleGoToLogin}>
                                    Go to Login
                                </Button>
                                {isLoggedIn && (
                                    <Button variant="primary" onClick={handleGoToHome}>
                                        Go to Dashboard
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default VerifyEmail; 