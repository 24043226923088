import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import posthog from 'posthog-js'
import { getUserProfile } from "../service/Reportify";

export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkToken: () => {},
  user: null,
});

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setLogin] = useState(false);
  const [user, setUser]= useState(null);
  const location = window.location;
  const path = location.pathname.split('/')[1] || '';
  const navigate = useNavigate();

  useEffect( () => {
    if (user) {
      posthog.identify(user.email, {
        email: user.email,
        tenant_id: user.tenant,
        user_code: user.userCode,
        $initial_referrer: document.referrer
      })
    }
  },[user])

  useEffect( () => {
    checkToken();
  },[]);

  function login(u) {
    const token = u.accessToken;
    setUser(u)
    setLogin(true);
    if (token) {
      window.localStorage.setItem("accessToken", token)

      if (location.pathname === "/" || path === "sign-up" ) {
        navigate('/dashboard', { replace: true })
      }
    }
  }

  function logout() {
    setLogin(false);
    setUser(null);
    window.localStorage.removeItem("accessToken")
    if (['reset-password', 'sign-up', 'public'].includes(path)){
      setUser(null)
    }else if(window.location.pathname !== "/")
      navigate('/', { replace: true })
    setUser(null)
  }

  async function checkToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      setLogin(true);
      getUserProfile().then(value => {
        setUser(value.data)
        if (location.pathname === "/" || path === "sign-up" ) {
          navigate('/dashboard', { replace: true })
        }
    })
    } else {
      logout();
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        checkToken,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
