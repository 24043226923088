import './App.css';
import AuthContextProvider from "./contexts/authenticationContext";
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";
import Home from "./components/Home";
import UserList from "./components/users/UserList";
import Patient from "./components/Patient";
import TestGroup from "./components/TestGroup";
import Profile from "./components/Profile";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import React, {useContext, useEffect, useState} from 'react';
import ResetPassword from "./components/ResetPassword";
import CreatePatient from "./components/CreatePatient";
import Report from "./components/Report";
import CreateReport from "./components/CreateReport";
import PatientDetail from "./components/PatientDetail";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RefererList from "./components/RefererList";
import CreateReferer from "./components/CreateReferer";
import PageSetup from "./components/PageSetup";
import {wakeUp} from "./service/Reportify";
import NewTestGroup from "./components/NewTestGroup";
import TestGroupDisplay from "./components/TestGroupDisplay";
import RegistrationList from "./components/registration/RegistrationList";
import NewRegistration from "./components/registration/NewRegistration";
import ViewRegistration from "./components/registration/ViewRegistration";
import ViewPdf from "./pdf/ViewPdf";
import SplashScreen from "./components/splash-screen/SplashScreen";
import DownloadReport from "./components/public/DownloadReport";
import PermissionProvider from "./contexts/permissions/PermissionProvider";
import { SuperAdmin } from './components/super-admin/SuperAdmin';
import {getApplicationName} from "./util/host";
import VerifyEmail from "./components/VerifyEmail";
import Layout from "./components/Layout";
import Dashboard from "./components/Dashboard";

function App() {
    const [showSplash, setShowSplash] = useState(true)
    const [progress, setProgress] = useState(null)

    useEffect(() => {
        setInterval(()=>{
            wakeUp().then(value => {})
        }, 10*60*1000);
        const wakeUpServer = async () => {
            try {
                const timeoutDuration = 500; //0.5 second
                const apiCallPromise = wakeUp();
                const timeoutPromise = new Promise((_, reject) => {
                    setTimeout(() => {
                        // setShowSplash(true)
                        // reject(new Error('Timeout exceeded'));
                    }, timeoutDuration);
                });
                const result = await Promise.race([apiCallPromise, timeoutPromise]);
                if (result) {
                    setProgress(100);
                    setTimeout(()=>{
                        setShowSplash(false);
                    },500)
                }
            } catch (error) {
                setShowSplash(false);
            }
        };
        wakeUpServer();
    }, []);

    return ( showSplash ? <SplashScreen _progress={progress}/> :
        <div>
            <Router>
                <AuthContextProvider>
                    <PermissionProvider>
                    <Routes>
                        {/* Authentication routes outside of Layout */}
                        <Route path="/" element={<Login/>}/>
                        <Route path={"/sign-up"} element={<SignUp />}/>
                        <Route path={"/reset-password/:verificationToken"} element={<ResetPassword/>}/>
                        <Route path={"/public/report/:patientId/:createdAt"} element={<DownloadReport/>}/>
                        <Route path='/verify-email/:token' element={<VerifyEmail/>}/>
                        
                        {/* Protected routes inside Layout */}
                        <Route path="*" element={
                            <Layout>
                                <Routes>
                                    <Route path={"/dashboard"} element={<Dashboard/>}/>
                                    <Route path={"/report"} element={<Report/>}/>
                                    <Route path={"/create-report/:patientTestGroupId"} element={<CreateReport/>}/>
                                    <Route path={"/user"} element={<UserList/>}/>
                                    <Route path={"/page-setup"} element={<PageSetup/>}/>
                                    <Route path={"/referer"} element={<RefererList/>}/>
                                    <Route path={"/referer/create"} element={<CreateReferer/>}/>
                                    <Route path={"/referer/update/:refererId"} element={<CreateReferer/>}/>
                                    <Route path={"/patient"} element={<Patient/>}/>
                                    <Route path={"/patient/create"} element={<CreatePatient/>}/>
                                    <Route path={"/update-patient/:patientId"} element={<CreatePatient/>}/>
                                    <Route path={"/patient/:patientId"} element={<PatientDetail/>}/>
                                    <Route path={"/test-group"} element={<TestGroup/>}/>
                                    <Route path={"/test-group/view/:testGroupId"} element={<TestGroupDisplay/>}/>
                                    <Route path={"/test-group/create"} element={<NewTestGroup/>}/>
                                    <Route path={"/test-group/update/:testGroupId"} element={<NewTestGroup/>}/>
                                    <Route path={"/registration"} element={<RegistrationList/>}/>
                                    <Route path={"/registration/create"} element={<NewRegistration/>}/>
                                    <Route path={"/registration/view/:registrationId"} element={<ViewRegistration/>}/>
                                    <Route path={"/profile"} element={<Profile/>}/>
                                    <Route path={"/patient/:patientId/view-report"} element={<ViewPdf/>}/>
                                    <Route path='/super-admin' element={<SuperAdmin/>}/>
                                    <Route path="*" element={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: "100%"
                                        }}>
                                            <h3>Not found</h3>
                                        </div>}/>
                                </Routes>
                            </Layout>
                        } />
                    </Routes>
                    </PermissionProvider>
                </AuthContextProvider>
            </Router>
            <ToastContainer/>
        </div>
    );
}

export default App;
